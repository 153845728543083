import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useDoubleRekeningUIContext } from '../../DoubleRekeningUIContext';
import { initialFilter } from '../../DoubleRekeningUIHelpers';

const prepareFilter = (queryParamsDetail, values) => {
	const { searchName } = values;
	const newQueryParams = { ...queryParamsDetail };
	const filter = {};

	//add to Object filter
	filter.name = searchName ?? '';
	newQueryParams.filter = filter;
	return newQueryParams;
};

export const DoubleRekeningFilterDetail = ({ listLoading }) => {
	// DoubleRekening UI Context
	const doubleRekeningUIContext = useDoubleRekeningUIContext();
	const doubleRekeningUIProps = useMemo(() => {
		return {
			setQueryParamsDetail: doubleRekeningUIContext.setQueryParamsDetail,
			queryParamsDetail: doubleRekeningUIContext.queryParamsDetail,
			filterType: doubleRekeningUIContext.filterType,
		};
	}, [doubleRekeningUIContext]);

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(doubleRekeningUIProps.queryParamsDetail, values);
		if (!isEqual(newQueryParams, doubleRekeningUIProps.queryParamsDetail)) {
			newQueryParams.pageNumber = 1;
			doubleRekeningUIProps.setQueryParamsDetail(newQueryParams);
		}
	};

	return (
		<Formik initialValues={initialFilter.filter} onSubmit={(values) => {
			applyFilter(values);
		}}
		>{({ values, handleSubmit, handleBlur, setFieldValue }) => (
			<form onSubmit={handleSubmit} className="form form-label-right">
				<div className="form-group row">
					<div className="col-lg-12">
						<input
							type="text"
							className="form-control"
							name="searchName"
							placeholder="Search"
							onBlur={handleBlur}
							value={values.searchName}
							onChange={(e) => {
								setFieldValue('searchName', e.target.value);
								handleSubmit();
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Name
						</small>
					</div>
				</div>
			</form>
		)}
		</Formik>

	);
};

