export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 }
];

export const initialValues = {
    file: undefined,
    channel_uuid: '',
    type: '',
    program_uuid: undefined,
    project_uuid: undefined,
    send_notif: '',
    data_import: [],
};

export const initialFilter = {
    filter: {
        bank: "",
        type: "",
        channel_name: "",
        status: "",
        donation_type: "",
        start_date: "",
        end_date: "",
        donation_status: "",
    },
    pageNumber: 1,
    pageSize: 10
};