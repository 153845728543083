import * as requestFromServer from './paymentCrud'
import { paymentSlice, callTypes } from './paymentSlice'
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = paymentSlice;

export const fetchPayment = (payload) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer.getFindPayment(payload)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.paymentFetched({ total_items, items }));
        })
        .catch(error => {
            if (error.response.status === 403 && error.response.statusText === "Forbidden") {
                toast.warn(error.response.data.message, toastOption);
            }
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
};

export const fetchPaymentById = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.paymentFetchedById({ bankForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getFindPaymentById(id)
        .then(response => {
            const { data } = response.data; // alias data -> donatur
            dispatch(actions.paymentFetchedById({ paymentForEdit: data }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createPayment = (body) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.insertPayment(body)
        .then(response => {
            dispatch(actions.paymentReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updatePayment = (body, uuid) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updatePayment(body, uuid)
        .then(response => {
            dispatch(actions.paymentReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const deletePayment = (uuid) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.deletePayment(uuid)
        .then(response => {
            dispatch(actions.paymentReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};