import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

export function PaymentHeader({ id, is_delete }) {
    const [title, setTitle] = useState("");

    useEffect(() => {
        let _title = id ? "" : "New Payment";
        if (id) _title = "Edit Payment";
        if (is_delete) _title = "Delete Payment";

        setTitle(_title);
    }, []);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">{title}</Modal.Title>
            </Modal.Header>
        </>
    );
}