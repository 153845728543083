import React from 'react';

export function PrivateNumber(cell, row) {
    if (cell){
        let number = []
        for (let i = 0; i <= cell.length; i++) {
            if (i > cell.length - 3) {
                number.push('*')
            } else {
                number.push(cell[i])
            }
        }
        return (
            <span>{number.join('')}</span>
        );
    }else {
        return <span>-</span>
    }
}

export function PrivateEmail(cell, row) {
    if (cell){
        var maskedEmail = cell.replace(/([^@.])/g, "*").split('');
        var previous = "";
        for (let i = 0; i < maskedEmail.length; i++) {
            if (i <= 2 || previous === "." || previous === "@") {
                maskedEmail[i] = cell[i];
            }
            previous = cell[i];
        }
        return (
            <span>{maskedEmail.join('')}</span>
        );
    }else {
        return <span>-</span>
    }
}

