import React from 'react';
import { ReconcilePaymentUIProvider } from "./ReconcilePaymentUIContext";
import { ReconcilePaymentCard } from './ReconcilePaymentCard';


export function ReconcilePaymentPage({ history }) {
    const uiEvent = {
        openDetail: (type) => {
            history.pushState(`/donasi/settlement/${type}/detail`);
        }
    }

    return (
        <ReconcilePaymentUIProvider uiEvent={uiEvent} history={history}>
            <ReconcilePaymentCard />
        </ReconcilePaymentUIProvider>
    );
}