import React from 'react';
import Moment from 'react-moment';

export const DateColumnFormatter = (
    cellContent,
    row,
    rowIndex,
) => {
    const offsetHours = 0;
    let utcJakarta = new Date(new Date(cellContent).getTime() + offsetHours * 60 * 60 * 1000);
    return (
        <>
            <Moment format="DD-MMM-YYYY HH:mm:ss">{utcJakarta}</Moment>
        </>
    )
};
