export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 }
];

export const initialFilter = {
    filter: {
        name: "",
        code: "",
    },
    pageNumber: 1,
    pageSize: 10
};

export const db = [
    {
        text: "Yayasan",
        value: "YAYASAN"
    },
    {
        text: "Wakaf",
        value: "WAKAF"
    },

]

export const DB_COA = {
    YAYASAN: 'YAYASAN',
    WAKAF: 'WAKAF',
}

export const account_type = [
    { value: "CASH_BANK", text: "Kas & Bank" },
    { value: "ACCOUNT_RECEIVABLE", text: "Piutang Usaha" },
    { value: "INVENTORY", text: "Persediaan" },
    { value: "OTHER_CURRENT_ASSET", text: "Aset Lancar Lainnya" },
    { value: "FIXED_ASSET", text: "Aset Tetap" },
    { value: "ACCUMULATED_DEPRECIATION", text: "Akumulasi Penyusutan" },
    { value: "OTHER_ASSET", text: "Aset Lainnya" },
    { value: "ACCOUNT_PAYABLE", text: "Hutang Usaha" },
    { value: "OTHER_CURRENT_LIABILITY", text: "Kewajiban Jangka Pendek" },
    { value: "LONG_TERM_LIABILITY", text: "Kewajiban Jangka Panjang" },
    { value: "EQUITY", text: "Modal" },
    { value: "REVENUE", text: "Pendapatan" },
    { value: "COGS", text: "Beban Pokok Penjualan" },
    { value: "EXPENSE", text: "Beban" },
    { value: "OTHER_EXPENSE", text: "Beban Lainnya" },
    { value: "OTHER_INCOME", text: "Pendapatan Lainnya" },
]
