import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    Input,
    Select
} from '../../../../../../_metronic/_partials/controls';
import * as BranchReq from '../../../_redux/branchs/branchCrud'
import * as BankReq from '../../../_redux/bank/bankCrud'
import { account_type, db } from '../../fundtypes/FundTypeUIHelpers';
import { getCoaAccountNumber, getCoaDbList } from '../../../_redux/fund_type/fundtypeCrud';
import { toast } from "react-toastify";
import { copyValue, toastOption } from '../../../../../../_metronic/_helpers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


// Validation schema
const BankPoolingEditSchema = Yup.object().shape({
    account_number: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Nomor Akun wajb diisi'),
    branch_uuid: Yup.string()
        .required('Cabang wajb diisi'),
    bank_uuid: Yup.string()
        .required('Bank wajb diisi'),
});

export function BankPoolingEditForm({ saveBankPooling, actionsLoading, bankPooling, onHide }) {

    const [bankList, setBankList] = useState([])
    const [branchList, setBranchList] = useState([])

    const [DB, setDB] = useState('')
    const [AccountType, setAccountType] = useState('')
    const [AccountNumber, setAccountNumber] = useState([])
    const [SelectedAccountNumber, setSelectedAccountNumber] = useState('')
    const [LoadingAccountNumber, setLoadingAccountNumber] = useState(false)
    const [dbList, setDbList] = useState([])
    const [selectedDb, setSelectedDb] = useState({})

    const getAccountNumber = async () => {
        setAccountNumber([])
        if (DB && AccountType) {
            setLoadingAccountNumber(true)
            getCoaAccountNumber(DB, AccountType).then((response) => {
                setAccountNumber(response.data.d)
                setLoadingAccountNumber(false)
            }).catch((error) => {
                setLoadingAccountNumber(false)
                toast.error(error.response.data.message, toastOption)
            })
        }
    }

    useEffect(() => {
        setDB(bankPooling.db)
        setAccountType(bankPooling.account_type)
        // if (!SelectedAccountNumber) {
        //     setSelectedAccountNumber(null)
        //     // fundType.account_number = null
        // }

        if (bankPooling.db) {
            const accountType = account_type.find(item => item.value == bankPooling.account_type)
            getCoaAccountNumber(bankPooling.db, [accountType.value]).then(res => {
                const { data } = res
                setAccountNumber(data.data)
            })
        }

    }, [bankPooling])

    // useEffect(() => {

    //     getAccountNumber()
    // }, [DB, AccountType])

    const getBranch = () => {
        BranchReq.getBranchAll().then((response) => {
            setBranchList(response.data.data)
        })
    }

    const getBank = () => {
        BankReq.getBankAll().then((response) => {
            setBankList(response.data.data)
        })
    }

    useEffect(() => {
        getBank()
        getBranch()
        findCoaDbList()
    }, [])

    async function findCoaDbList() {
        const { data } = await getCoaDbList()
        setDbList(data.data.d)
    }

    async function handleChangeAccType(e) {
        setAccountNumber([])
        const accountType = e.target.value;
        const { data } = await getCoaAccountNumber(DB, [accountType]);
        setAccountNumber(data.data);
    }

    let debounceTimeout;
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={bankPooling}
                validationSchema={BankPoolingEditSchema}
                onSubmit={(values) => {

                    // console.log('====================================');
                    // console.log(values);
                    // console.log('====================================');
                    saveBankPooling(values)
                }
                }
            >
                {({ handleSubmit, values, setFieldValue }) => (
                    <>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            {actionsLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                            )}
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <Field
                                            name="account_number"
                                            component={Input}
                                            placeholder="Text"
                                            label="Nomor Akun"
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <Select
                                            name="branch_uuid"
                                            placeholder="Text"
                                            label="Cabang"
                                        >
                                            <option value=""></option>
                                            {
                                                branchList.map((item) => {
                                                    return <option key={item.uuid} value={item.uuid}>{item.name}</option>
                                                })
                                            }
                                        </Select>
                                        <ErrorMessage name='branch_uuid'
                                            render={msg => <div style={{ color: 'red', }}>{msg}</div>} />
                                    </div>
                                    <div className="col-lg-12">
                                        <Select
                                            name="bank_uuid"
                                            placeholder="Text"
                                            label="Bank"
                                        >
                                            <option value=""></option>
                                            {
                                                bankList.map((item) => {
                                                    return <option key={item.uuid} value={item.uuid}>{item.name}</option>
                                                })
                                            }
                                        </Select>
                                        <ErrorMessage name='bank_uuid'
                                            render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                                    </div>

                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <Select
                                            name="db"
                                            placeholder="Text"
                                            label="Database"
                                            onChange={(e) => {
                                                setFieldValue('account_type', "")
                                                setAccountNumber([])
                                                setDB(e.target.value)
                                                setLoadingAccountNumber(true)
                                                setFieldValue('db', e.target.value)
                                                setFieldValue('account_number_coa', null)
                                            }}
                                        >
                                            <option value="">PILIH</option>
                                            {
                                                db.map((item) => {
                                                    return <option key={item.text} value={item.value}>{item.text}</option>
                                                })
                                            }

                                        </Select>
                                        <ErrorMessage name='db'
                                            render={msg => <div style={{ color: 'red', }}>{msg}</div>} />
                                    </div>

                                </div>
                                <div className="row form-group">
                                    <div className="col-lg-12">
                                        <Select
                                            name="account_type"
                                            placeholder="Text"
                                            label="Tipe"
                                            onChange={(e) => {
                                                handleChangeAccType(e)
                                                setAccountType(e.target.value)
                                                setLoadingAccountNumber(true)
                                                setFieldValue('account_type', e.target.value)
                                                setFieldValue('account_number_coa', null)

                                            }}
                                        >
                                            <option value="">PILIH</option>
                                            {
                                                account_type.map((item) => {
                                                    return <option key={item.text} value={item.value}>{item.text}</option>
                                                })
                                            }
                                        </Select>
                                        <ErrorMessage name='account_type'
                                            render={msg => <div style={{ color: 'red', }}>{msg}</div>} />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-lg-12">
                                        {bankPooling &&
                                            <div>
                                                <label>Select Nomor Akun</label>
                                                <Autocomplete
                                                    name="account_number_coa"
                                                    options={AccountNumber}
                                                    getOptionLabel={(option) => option ? `${option.no} - ${option.name}` : ''}
                                                    // defaultValue={channelFiltered.find((option) => option.uuid === values.channel_uuid)}
                                                    onChange={(e, value) => {
                                                        if (value) {
                                                            // setSelectedAccountNumber(value)
                                                            setFieldValue('account_number_coa', value.no)
                                                        } else {
                                                            setFieldValue('account_number_coa', "")
                                                            // setSelectedAccountNumber(value)
                                                        }

                                                        clearTimeout(debounceTimeout);
                                                    }}
                                                    onInputChange={(e, newInputValue, reason) => {
                                                        if (reason === 'input') {
                                                          if (debounceTimeout) {
                                                            clearTimeout(debounceTimeout);
                                                          }
                                                      
                                                          // Set a new timeout
                                                          debounceTimeout = setTimeout(() => {
                                                            getCoaAccountNumber(values.db, [values.account_type], newInputValue).then(res => {
                                                              const { data } = res;
                                                              setAccountNumber(data.data);
                                                            });
                                                          }, 1000); // 1000 ms = 1 detik
                                                        }
                                                      }}
                                                    value={AccountNumber.length > 0 && AccountNumber.find((item) => item.no === values.account_number_coa)}
                                                    loading={LoadingAccountNumber}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Pilih Account Number"
                                                            variant="outlined"
                                                            disableClearable
                                                            size="small"

                                                        />
                                                    )}
                                                />
                                                <label>Harap Pilih <b>Nomor Akun</b></label>
                                                <ErrorMessage name='account_number_coa'
                                                    render={msg => <div style={{ color: 'red', }}>{msg}</div>} />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Cancel
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                Save
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}

