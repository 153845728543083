/* eslint-disable jsx-a11y/anchor-is-valid */
import { CircularProgress } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  currencyFormatter,
} from "../../../../../_metronic/_helpers";
import { color } from "../FirstRow/StylingHelper";

export function TilesDonorTypeWidget({
  className,
  iconColor = "info",
  widgetHeight = "150px",
  data = [],
  loading = false,
  icon = toAbsoluteUrl("/media/svg/icons/Layout/Layout-Grid.svg")
}) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className={`card-body d-flex justify-content-center no-wrap text-center`}>
          {loading ? <div className="text-center align-self-center"><CircularProgress /></div> :
            <div className="align-self-center">
              <i
                class="fas fa-users"
                style={{ color: color.primaryPurple, fontSize: "36px" }}
              ></i>
              {/* <div className="text-muted font-weight-bold font-size-lg mt-8">
                Total Donatur:
              </div>
              <div className="text-dark font-weight-bolder font-size-h2 mt-1">
                {value}
              </div> */}
              {
                !loading && data.length == 0 &&
                <p
                  className="text-hover-primary text-center font-weight-bold font-size-h4"
                >
                  Data Tipe Donatur <br /> <br />
                  Data Kosong
                </p>
              }

              {data.map((item) => {
                return <div>
                  <div className="text-muted font-weight-bold font-size-lg mt-3">
                    TOTAL {item.type}
                  </div>
                  <div className="text-dark font-weight-bolder font-size-h2 mt-1">
                    {new Intl.NumberFormat('id-ID').format(item.total_donatur)}
                  </div>
                </div>
              })}


            </div>
          }
        </div>
      </div>
    </>
  );
}
