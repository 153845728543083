import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

import { programSlice } from "../app/modules/ProgramProject/_redux/program/programSlice";
import { projectsSlice } from "../app/modules/ProgramProject/_redux/projects/projectsSlice";
// import { programSlice } from "../app/modules/MasterData/_redux/program/programSlice";
// import { projectsSlice } from "../app/modules/MasterData/_redux/projects/projectsSlice";
import { branchsSlice } from "../app/modules/MasterData/_redux/branchs/branchsSlice";
import { channelsSlice } from "../app/modules/MasterData/_redux/channels/channelsSlice";

import { donationSlice } from "../app/modules/Donation/_redux/donation/donationSlice";
import { bankImportSlice } from "../app/modules/Donation/_redux/bank-import/bankImportSlice";
import { bankKurbanSlice } from "../app/modules/Donation/_redux/bank-kurban/bankKurbanSlice";
import { donaturSlice } from "../app/modules/Donatur/_redux/donatur/donaturSlice";
import { bankSlice } from "../app/modules/MasterData/_redux/bank/bankSlice";
import { userSlice } from "../app/modules/MasterData/_redux/user/userSlice";
import { fundTypeSlice } from "../app/modules/MasterData/_redux/fund_type/fundtypeSlice";
import { collectorSlice } from "../app/modules/Collector2/_redux/collectorSlice";
import { checkerSlice } from "../app/modules/Checker2/_redux/checkerSlice";
import { confirmationSlice } from "../app/modules/Donation/_redux/confirmation/confirmationSlice";
import { voidSlice } from "../app/modules/Donation/_redux/void/voidSlice";
import { pickupSlice } from "../app/modules/Donation/_redux/pickup/pickupSlice";
import { eChannelSlice } from "../app/modules/Donation/_redux/echannel/eChannelSlice";
import { eChannelKurbanSlice } from "../app/modules/Donation/_redux/echannel-kurban/eChannelKurbanSlice";
import { templateSlice } from "../app/modules/MasterData/_redux/notification-template/templateSlice";
import { masterCategorySlice } from "../app/modules/MasterData/_redux/master-category/MasterCategorySlice";
import { recapSlice } from "../app/modules/Donation/_redux/recap/recapSlice";
import { kurbanSlice } from "../app/modules/Donation/_redux/kurban/kurbanSlice";
import { notificationConsolidationSlice } from "../app/modules/Donation/_redux/notification-consolidation/notificationConsolidationSlice";

import { consolidationSlice } from "../app/modules/Donation/_redux/master-consolidation/consolidationSlice";
import { exportListSlice } from "../app/modules/ExportList/_redux/exportListSlice";
import { printConsolidationSlice } from "../app/modules/Donation/_redux/PrintConsolidation/PrintConsolidationSlice";
import { departmentSlice } from "../app/modules/MasterData/_redux/department/departmentSlice";
import { goodsCategorySlice } from "../app/modules/MasterData/_redux/goods-category/goodsCategorySlice";
import { counterRecapSlice } from "../app/modules/Donation/_redux/counter-recap/counterRecapSlice";
import { donorGrowthSlice } from "../app/modules/Analysis/_redux/DonorGrowth/DonorGrowthSlice";
import { donorRankingSlice } from "../app/modules/Analysis/_redux/DonorRanking/DonorRankingSlice";
import { donorActiveSlice } from "../app/modules/Analysis/_redux/DonorActive/DonorActiveSlice";
import { counterRankingSlice } from "../app/modules/Analysis/_redux/CounterRanking/CounterRankingSlice";
import { autoSettleSlice } from "../app/modules/MasterData/_redux/auto-settle/AutoSettleSlice";
import { bankPoolingSlice } from "../app/modules/MasterData/_redux/bank-pooling/bankPoolingSlice";
import { reconcileSlice } from "../app/modules/Donation/_redux/reconcile/reconcileSlice";
import { oAuthAppSlice } from "../app/modules/MasterData/_redux/oauth-app/oAuthSlice";
import { masterOptionSlice } from "../app/modules/MasterData/_redux/master-option/masterOptionSlice";
import { accessRoleSlice } from "../app/modules/MasterData/_redux/access-role/accessRoleSlice";
import { hewanKurbanSlice } from "../app/modules/Donation/_redux/hewan-kurban/hewanKurbanSlice";
import { hewanKurbanCampaignSlice } from "../app/modules/Donation/_redux/hewan-kurban-campaign/hewanKurbanCampaignSlice";
import { cilkurSlice } from "../app/modules/Donation/_redux/cilkur/cilkurSlice";
import { echannelConfirmationSlice } from "../app/modules/Donation/_redux/echannel-confirmation/echannelConfirmationSlice";
import { wakafSlice } from "../app/modules/Donation/_redux/wakaf/wakafSlice";
import { collectRecapSlice } from "../app/modules/Donation/_redux/collect-recap/collectRecapSlice";
import { doubleNameSlice } from "../app/modules/Donatur/_redux/donatur-double-name/doubleNameSlice";
import { doubleRekeningSlice } from "../app/modules/Donatur/_redux/donatur-double-rekening/doubleRekeningSlice";
import { channelRoleSlice } from "../app/modules/MasterData/_redux/channel-role/channelRoleSlice";
import { mpProgramSlice } from "../app/modules/MasterData/_redux/mp-program/mp-program-slice";
import { mpCategorySlice } from "../app/modules/MasterData/_redux/mp-category/mp-category-slice";
import { mpBannerSlice } from "../app/modules/MasterData/_redux/mp-banner/mp-banner-slice";
import { mpReportSlice } from "../app/modules/MasterData/_redux/mp-report/mp-report-slice";
import { donorSourceSlice } from "../app/modules/MasterData/_redux/donor-source/donor-source-slice";
import { consolidationBatchSlice } from "../app/modules/ConsolidationBatch/_redux/consolidation-batch-slice";
import { donorImportSlice } from "../app/modules/DonorImport/_redux/donor-import-slice";
import { versionPlatformSlice } from "../_metronic/layout/components/footer/_redux/footerSlice";
import { partnershipSlice } from "../app/modules/Kurban/partnership/_redux/partnershipSlice";
import { periodTemplateSlice } from "../app/modules/Kurban/period-template/_redux/periodTemplateSlice";
import { thkChannelReportSlice } from "../app/modules/Kurban/thk-channel-report/_redux/thkChannelReportSlice";
import { logLoginSlice  } from "../app/modules/MasterData/_redux/log_login/logLoginSlice";
import { paymentSlice  } from "../app/modules/MasterData/_redux/payment/paymentSlice";
// import {reportSlice} from '../app/modules/Report'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  branchs: branchsSlice.reducer,
  projects: projectsSlice.reducer,
  donaturs: donaturSlice.reducer,
  channels: channelsSlice.reducer,
  donations: donationSlice.reducer,
  bankImport: bankImportSlice.reducer,
  bankKurban: bankKurbanSlice.reducer,
  eChannel: eChannelSlice.reducer,
  eChannelKurban: eChannelKurbanSlice.reducer,
  banks: bankSlice.reducer,
  programs: programSlice.reducer,
  fundtypes: fundTypeSlice.reducer,
  users: userSlice.reducer,
  collects: collectorSlice.reducer,
  checkers: checkerSlice.reducer,
  confirmations: confirmationSlice.reducer,
  voids: voidSlice.reducer,
  pickups: pickupSlice.reducer,
  notificationTemplate: templateSlice.reducer,
  masterCategory: masterCategorySlice.reducer,
  recapSlice: recapSlice.reducer,
  masterConsolidation: consolidationSlice.reducer,
  kurbanList: kurbanSlice.reducer,
  notificationConsolidationList: notificationConsolidationSlice.reducer,
  printConsolidation: printConsolidationSlice.reducer,
  exportList: exportListSlice.reducer,
  departments: departmentSlice.reducer,
  goodsCategories: goodsCategorySlice.reducer,
  counterRecap: counterRecapSlice.reducer,
  donorGrowth: donorGrowthSlice.reducer,
  donorRanking: donorRankingSlice.reducer,
  donorActive: donorActiveSlice.reducer,
  counterRanking: counterRankingSlice.reducer,
  autoSettle: autoSettleSlice.reducer,
  bankPoolings: bankPoolingSlice.reducer,
  reconcile: reconcileSlice.reducer,
  oauthApp: oAuthAppSlice.reducer,
  masterOptions: masterOptionSlice.reducer,
  accessRoles: accessRoleSlice.reducer,
  hewanKurbans: hewanKurbanSlice.reducer,
  hewanKurbanCampaigns: hewanKurbanCampaignSlice.reducer,
  cilkur: cilkurSlice.reducer,
  echannelConfirm: echannelConfirmationSlice.reducer,
  wakafList: wakafSlice.reducer,
  collectRecap: collectRecapSlice.reducer,
  doubleName: doubleNameSlice.reducer,
  doubleRekening: doubleRekeningSlice.reducer,
  channelRole: channelRoleSlice.reducer,
  mpProgram: mpProgramSlice.reducer,
  mpCategory: mpCategorySlice.reducer,
  mpBanner: mpBannerSlice.reducer,
  mpReport: mpReportSlice.reducer,
  donorSource: donorSourceSlice.reducer,
  consolidationBatch: consolidationBatchSlice.reducer,
  donorImport: donorImportSlice.reducer,
  versionPlatform: versionPlatformSlice.reducer,
  thkPartnership: partnershipSlice.reducer,
  thkPeriodTemplate: periodTemplateSlice.reducer,
  thkChannelReport: thkChannelReportSlice.reducer,
  logLogin: logLoginSlice.reducer,
  payment: paymentSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
