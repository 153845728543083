import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useBankPoolingUIContext } from '../BankPoolingUIContext';
import { BankPoolingEditDialogHeader } from './BankPoolingEditDialogHeader';
import { BankPoolingEditForm } from './BankPoolingEditForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/bank-pooling/bankPoolingAction';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../../_metronic/_helpers';

export const BankPoolingEditDialog = ({ id, show, onHide }) => {

    // BankPooling UI Context
    const bankPoolingUIContext = useBankPoolingUIContext();
    const bankPoolingUIProps = useMemo(() => {
        return {
            initBankPooling: bankPoolingUIContext.initBankPooling,
            queryParams: bankPoolingUIContext.queryParams,
            setQueryParams: bankPoolingUIContext.setQueryParams,
        };
    }, [bankPoolingUIContext]);

    // BankPooling Redux state
    const dispatch = useDispatch();
    const { actionsLoading, bankPoolingForEdit, error } = useSelector(
        (state) => ({
            actionsLoading: state.bankPoolings.actionsLoading,
            bankPoolingForEdit: state.bankPoolings.bankPoolingForEdit,
            error: state.bankPoolings.error
        }),
        shallowEqual,
    );

    if (error) {
        toast.error(error, toastOption)
    }
    useEffect(() => {
        // server call for getting BankPooling by id
        dispatch(actions.fetchBankPooling(id));
    }, [id, dispatch]);

    const saveBankPooling = (bankPooling) => {
        if (!id) {
            dispatch(actions.createBankPooling(bankPooling)).then((response) => {
                toast.success(response.message, toastOption);
                dispatch(actions.fetchBankPoolings(bankPoolingUIProps.queryParams)).then(() => onHide());
            });

        } else {
            dispatch(actions.updateBankPooling(bankPooling)).then((response) => {
                toast.success(response.message, toastOption);
                dispatch(actions.fetchBankPoolings(bankPoolingUIProps.queryParams)).then(() => onHide());
            });
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="modal-sizes-title-lg"
        >
            <BankPoolingEditDialogHeader id={id} />
            <BankPoolingEditForm
                saveBankPooling={saveBankPooling}
                actionsLoading={actionsLoading}
                bankPooling={bankPoolingForEdit || bankPoolingUIProps.initBankPooling}
                onHide={onHide}
            />
        </Modal>
    );
};
