/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function BankEditDialogHeader({ id }) {

    const [title, setTitle] = useState("");
    // Channel Redux state
    const { bankForEdit, actionsLoading } = useSelector(
        (state) => ({
            bankForEdit: state.banks.bankForEdit,
            actionsLoading: state.channels.actionsLoading,
        }),
        shallowEqual
    );

    useEffect(() => {
        let _title = id ? "" : "Bank Baru";
        if (bankForEdit && id) {
            _title = "Edit Bank";
        }

        setTitle(_title);
        // eslint-disable-next-line
    }, [bankForEdit, actionsLoading]);

    return (
        <>
            {actionsLoading && <ModalProgressBar variant="query" />}
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">{title}</Modal.Title>
            </Modal.Header>
        </>
    );
}
