import React, { createContext, useCallback, useContext, useState } from "react";
import { initialFilter } from './BankMutationUIHelpers'
import { isEqual, isFunction } from 'lodash';

const BankMutationUIContext = createContext();

export function useBankMutationUIContext() {
    return useContext(BankMutationUIContext);
}

export const BankMutationUIConsumer = useBankMutationUIContext.Consumer;

export function BankMutationUIProvider({ uiEvent, children }) {
    const [showUpload, setShowUpload] = useState(false);
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);
    
    const value = {
        uiEvent,
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        showUpload, 
        setShowUpload,
    };

    return (
        <BankMutationUIContext.Provider value={value}>
            {children}
        </BankMutationUIContext.Provider>
    );
}