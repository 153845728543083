import React from 'react';
import { Route } from 'react-router-dom';
import { BankPoolingCard } from './BankPoolingCard';
import { BankPoolingEditDialog } from './bank-pooling-edit-dialog/BankPoolingEditDialog';
import { BankPoolingUIProvider } from './BankPoolingUIContext';
import { PrivateRoute } from "../../../../PrivateRoute";
import { RoleChecker } from '../../../../RoleChecker';

export function BankPoolingPage({ history }) {

    const bankPoolingUIEvents = {
        newBankPoolingButtonClick: () => {
            history.push('/mastd-bank/pooling-bank/new');
        },
        openEditBankPoolingPage: (id) => {
            history.push(`/mastd-bank/pooling-bank/${id}/edit`);
        },
    };

    return (
        <BankPoolingUIProvider bankPoolingUIEvents={bankPoolingUIEvents}>

            <PrivateRoute role={RoleChecker('Create', 'bank_pooling')} component={BankPoolingEditDialog}
                path="/mastd-bank/pooling-bank/new" />
            <PrivateRoute role={RoleChecker('Edit', 'bank_pooling')} component={BankPoolingEditDialog}
                path="/mastd-bank/pooling-bank/:id/edit" />

            <Route path="/mastd-bank/pooling-bank/new">
                {({ history, match }) => (
                    <BankPoolingEditDialog
                        show={match != null}
                        onHide={() => {
                            history.push('/mastd-bank/pooling-bank');
                        }}
                    />
                )}
            </Route>

            <Route path="/mastd-bank/pooling-bank/:id/edit">
                {({ history, match }) => (
                    <BankPoolingEditDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push('/mastd-bank/pooling-bank');
                        }}
                    />
                )}
            </Route>

            <BankPoolingCard />
        </BankPoolingUIProvider>
    );
}
