import React from "react";
import { Modal } from "react-bootstrap";
import * as actions from '../../../_redux/payment/paymentAction';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../_metronic/_helpers";
import { PaymentHeader } from "./PaymentHeader";

export const PaymentDeleteDialog = ({ id, show, onHide }) => {
    const dispatch = useDispatch();
    const deletePayment = () => {
        dispatch(actions.deletePayment(id)).then((response) => {
            toast.success(response.message, toastOption);
            dispatch(actions.fetchPayment()).then(() => onHide());
        });
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="modal-sizes-title-lg"
        >
            <PaymentHeader is_delete={true}/>
            <Modal.Body className="overlay overlay-block cursor-default">
                <h6 className="text-">Apakah anda yakin ingin menghapus payment ini.</h6>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Cancel
                </button>
                <> </>
                <button
                    type="submit"
                    onClick={deletePayment}
                    className="btn btn-danger btn-elevate"
                >
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    );
}