import React from 'react';
import Moment from 'react-moment';

export const DateColumnFormatter = (
    cellContent, pageFormat
) => (
    <>
        {cellContent !== null ? (
            <Moment format={pageFormat}>{cellContent}</Moment>
        ) : (
            <span>-</span>
        )}
    </>
);
