import { createSlice } from '@reduxjs/toolkit';

const initialBankMutationState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    dataForEdit: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const bankMutationSlice = createSlice({
    name: 'bankMutation',
    initialState: initialBankMutationState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // findBankMutation
        bankMutationFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },

        bankMutationFetchedById: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.dataForEdit = action.payload.dataForEdit;
        },

        // Set to default state actionLoading & error
        bankMutationReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

