import * as requestFromServer from './bankCrud';
import { bankSlice, callTypes } from './bankSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = bankSlice;

export const fetchBanks = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.findBank(queryParams)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.banksFetched({ total_items, items }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchBank = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.bankFetched({ bankForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getBankById(id)
        .then(response => {
            const { data: bank } = response.data; // alias data -> donatur
            dispatch(actions.bankFetched({ bankForEdit: bank }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createBank = (bankForCreation) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.createBank(bankForCreation)
        .then(response => {
            dispatch(actions.bankReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateBank = (bank) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updateBank(bank)
        .then(response => {
            dispatch(actions.bankReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
