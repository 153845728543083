import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import * as reqBankMutation from "../../../_redux/bank-mutation/bankMutationCrud";
import BootstrapTable from "react-bootstrap-table-next";
import { currencyFormatter } from "../../../../../../_metronic/_helpers";
import moment from "moment";

export function BankMutationDetail({ history, match: { params: { id }}}) {
    const [bankMutationItem, setBankMutationItem] = useState([]);

    const columns = [
        {
            dataField: 'type',
            text: 'Tipe Data',
            formatter: (cell) => {
                let type = '';
                switch (cell) {
                    case '1':
                        type = 'Donasi'
                        break;
                    case '2':
                        type = 'Collection'
                        break;
                    case '3':
                        type = 'Lainnya'
                        break;
                };

                return type;
            }
        },
        {
            dataField: 'type_desc',
            text: 'Keterangan tipe',
            formatter: (cell) => cell ? cell : '-'
        },
        {
            dataField: 'status',
            text: 'Status',
        },
        {
            dataField: 'trx_date',
            text: 'Tanggal Transfer',
            formatter: (cell) => moment(cell).format('DD MMM YYYY')
        },
        {
            dataField: 'amount',
            text: 'Jumlah',
            formatter: (cell) => currencyFormatter(+cell || 0)
        },
        {
            dataField: 'description',
            text: 'Pesan Bank',
        },
        {
            dataField: 'action',
            text: '',
            formatter: (_, row) => (
                <div className="my-2">
                    <button 
                        className="btn btn-primary btn-sm" 
                        onClick={() => history.push(`/donasi/bank/${row.bank_statement_uuid}/process-v2`)} 
                        disabled={row.type !== '1'}
                    >
                        View Bank Statement
                    </button>
                </div>
            )
        }
    ];

    const fetchBankMutationById = async () => {
        const result = await reqBankMutation.getFindBankMutationById(id);
        setBankMutationItem(result.data.data)
    };

    useEffect(() => {
        fetchBankMutationById();
    }, []);

    return (
        <Card>
            <CardHeader title={'Bank Mutation Detail'}>
                <CardHeaderToolbar>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => history.push('/mastd-bank/bank-mutation')}
                    >
                      <i className="fa fa-arrow-left"></i>
                      Back
                    </button>
                </CardHeaderToolbar>
            </CardHeader>

            <CardBody>
                <div className="mb-5">
                    <table>
                        <tbody>
                            <tr>
                                <td width="45%"><p><b>Nama Channel</b></p></td>
                                <td><p><b>: {bankMutationItem[0]?.name}</b></p></td>
                            </tr>
                            <tr>
                                <td width="45%"><p><b>No Batch</b></p></td>
                                <td><p><b>: {bankMutationItem[0]?.batch_id}</b></p></td>
                            </tr>
                            <tr>
                                <td width="45%"><p><b>Dibuat oleh</b></p></td>
                                <td><p><b>: {bankMutationItem[0]?.user_name}</b></p></td>
                            </tr>
                            <tr>
                                <td width="45%"><p><b>Tanggal dibuat</b></p></td>
                                <td><p><b>: {moment(bankMutationItem[0]?.created_at).format('DD MMM YYYY')}</b></p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <BootstrapTable 
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="uuid"
                    data={bankMutationItem || []}
                    columns={columns}
                />
            </CardBody>
        </Card>
    );
}