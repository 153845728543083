/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CircularProgress } from "@material-ui/core";

export function TileCustomWidget({
  className,
  iconColor = "success",
  widgetHeight = "300px",
  title = "",
  value = "",
  subTitle = "",
  subValue = "",
  loading = false,
  icon = toAbsoluteUrl("/media/svg/icons/Layout/Layout-Grid.svg")
}) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className={`card-body ${loading && "d-flex justify-content-center"} text-center`}>
          {
            loading ? <div className="text-center align-self-center"><CircularProgress /></div> :
              <div className="d-flex justify-content-center align-content-center">
                <div className="align-self-center">
                  <span className={`svg-icon svg-icon-5x svg-icon-${iconColor}`}>
                    <SVG
                      src={icon}
                    />
                  </span>
                  <p
                    className="text-hover-primary font-weight-bold font-size-h4 mt-2"
                  >
                    {title}
                  </p>
                  <div className="text-dark font-weight-bolder font-size-h2">
                    {value}
                  </div>
                  <p
                    className="text-hover-primary font-weight-bold font-size-h4 mt-2"
                  >
                    {subTitle}
                  </p>
                  <div className="text-dark font-weight-bolder font-size-h2">
                    {subValue}
                  </div>
                </div>
              </div>

          }


        </div>
      </div>
    </>
  );
}
