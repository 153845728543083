import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/bank-pooling/bankPoolingAction';
import * as uiHelpers from '../BankPoolingUIHelpers';

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { useBankPoolingUIContext } from '../BankPoolingUIContext';
import { RoleChecker } from '../../../../../RoleChecker';
// import { ToastContainer } from 'react-toastify';

const BankPoolingTable = (props) => {
    // BankPooling UI Context
    const bankPoolingUIContext = useBankPoolingUIContext();
    const bankPoolingUIProps = useMemo(() => {
        return {
            queryParams: bankPoolingUIContext.queryParams,
            setQueryParams: bankPoolingUIContext.setQueryParams,
            openEditBankPoolingPage: bankPoolingUIContext.openEditBankPoolingPage,
        };
    }, [bankPoolingUIContext]);


    // Getting curret state of bankPooling list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.bankPoolings }),
        shallowEqual,
    );

    const { totalCount, entities, listLoading } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {

        //Requset ke Branch Action
        dispatch(actions.fetchBankPoolings(bankPoolingUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankPoolingUIProps.queryParams, dispatch]);

    const allowEdit = RoleChecker('Edit', 'bank_pooling')

    // Table columns
    const columns = [
        {
            dataField: 'account_number',
            text: 'Nomer Akun',
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: 'bank.name',
            text: 'Bank',
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: 'branch.name',
            text: 'Cabang',
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditBankPoolingPage: bankPoolingUIProps.openEditBankPoolingPage,
                allowEdit
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: bankPoolingUIProps.queryParams.pageSize,
        page: bankPoolingUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    bankPoolingUIProps.setQueryParams,
                                )}
                                // selectRow={getSelectRow({
                                //   entities,
                                //   ids: productsUIProps.ids,
                                //   setIds: productsUIProps.setIds,
                                // })}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};

export default BankPoolingTable;
