/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { RoleChecker } from "../../../../../app/RoleChecker";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile d-print-none ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav d-print-none ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/dashboard")}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {navigator.userAgent === "sandra-mobile-app" && (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">Konter</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {RoleChecker("Read", "list_donasi") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive("/donasi/counter/list")}`}
              >
                <NavLink className="menu-link" to="/donasi/counter/list">
                  <span className="menu-text">Daftar Donasi Konter</span>
                </NavLink>
              </li>
            )}
            {RoleChecker("Create Donation", "detail_donasi") && (
              <li className={`menu-item menu-item-rel ${getMenuItemActive("/donasi/counter/new")}`}>
                <NavLink className="menu-link" to="/donasi/counter/new">
                  <span className="menu-text">Input</span>
                </NavLink>
              </li>
            )}
            {RoleChecker("Read", "collect_donasi_konter") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/donasi/counter/collect"
                )}`}
              >
                <NavLink className="menu-link" to="/donasi/counter/collect">
                  <span className="menu-text">Collect</span>
                </NavLink>
              </li>
            )}
            {RoleChecker("Read", "rekap_konter") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive("/donasi/counter/recap")}`}
              >
                <NavLink className="menu-link" to="/donasi/counter/recap">
                  <span className="menu-text">Rekapan Konter</span>
                </NavLink>
              </li>
            )}
            {RoleChecker("Read", "collector") && (
              <li className="menu-section ">
                <h4 className="menu-text">Collector</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}
            {RoleChecker("Read", "collector") && (
              <li className={`menu-item ${getMenuItemActive("/collector/list")}`}>
                <NavLink className="menu-link" to="/collector">
                  <span className="menu-text">List</span>
                </NavLink>
              </li>
            )}

            {RoleChecker("Collect", "collector") && (
              <li className={`menu-item ${getMenuItemActive("/collector/collect")}`}>
                <NavLink className="menu-link" to="/collector/collect">
                  <span className="menu-text">Collect</span>
                </NavLink>
              </li>
            )}
            {RoleChecker("Read", "checker") && (
              <li className="menu-section ">
                <h4 className="menu-text">Checker</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}
            {RoleChecker("Read", "checker") && (
              <li className={`menu-item menu-item-rel ${getMenuItemActive("/checker")}`}>
                <NavLink className="menu-link" to="/checker">
                  <span className="menu-text">Checker</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}
            <li className="menu-section ">
              <h4 className="menu-text">Menu</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          </>
        )}

        {/*Donasi Navigation*/}
        {RoleChecker("Read", "donasi") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/donasi")}`}
          >
            <NavLink className="menu-link menu-toggle" to="/donasi/list">
              <span className="menu-text">Donasi</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {RoleChecker("Read", "list_donasi") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/donasi/list")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/donasi/list">
                      <span className="menu-text">Daftar Donasi</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "bank_import") && (
                  <>
                    {/*BANK*/}
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive("/donasi/bank")}`}
                      data-menu-toggle="hover"
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link menu-toggle" to="/donasi/bank">
                        <span className="menu-text">Bank</span>
                        <i className="menu-arrow" />
                      </NavLink>

                      <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                        <ul className="menu-subnav">
                          {/*begin::3 Level*/}
                          <li className={`menu-item ${getMenuItemActive("/donasi/bank/list")}`}>
                            <NavLink className="menu-link" to="/donasi/bank/list">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Daftar Bank</span>
                            </NavLink>
                          </li>
                          {/* {RoleChecker("Create Donation Echannel dan Bank", "detail_donasi") &&
                                                        <li className={`menu-item ${getMenuItemActive('/donasi/bank/input/new')}`}>
                                                            <NavLink className="menu-link" to="/donasi/bank/input/new">
                                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                                <span className="menu-text">Input Donasi Echannel</span>
                                                            </NavLink>
                                                        </li>} */}
                          {RoleChecker("Import Bank", "bank_import") && (
                            <li className={`menu-item ${getMenuItemActive("/donasi/bank/import")}`}>
                              <NavLink className="menu-link" to="/donasi/bank/import">
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">Import Bank</span>
                              </NavLink>
                            </li>
                          )}
                          {RoleChecker("Import Bank", "bank_import") && (
                            <li className={`menu-item ${getMenuItemActive("/donasi/bank/kurban")}`}>
                              <NavLink className="menu-link" to="/donasi/bank/kurban">
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">Import Bank Kurban</span>
                              </NavLink>
                            </li>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                )}

                {/*eChannel*/}
                {RoleChecker("Read", "echannel_import") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donasi/echannel"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/donasi/echannel">
                      <span className="menu-text">E-Channel</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        {RoleChecker("Read", "echannel_import") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/echannel/list")}`}>
                            <NavLink className="menu-link" to="/donasi/echannel/list">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Daftar E-Channel</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Import Echannel", "echannel_import") && (
                          <>
                            {RoleChecker("Create Donation Echannel dan Bank", "detail_donasi") && (
                              <li
                                className={`menu-item ${getMenuItemActive(
                                  "/donasi/echannel/input/new"
                                )}`}
                              >
                                <NavLink className="menu-link" to="/donasi/echannel/input/new">
                                  <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                  </i>
                                  <span className="menu-text">Input Donasi Echannel</span>
                                </NavLink>
                              </li>
                            )}

                            {RoleChecker("Create Donation Echannel dan Bank", "detail_donasi") && (
                              <li className={`menu-item ${getMenuItemActive("/donasi/echannel/new-v2")}`}>
                                <NavLink className="menu-link" to="/donasi/echannel/new-v2">
                                  <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                  </i>
                                  <span className="menu-text">Input Donasi Echannel v2</span>
                                </NavLink>
                              </li>
                            )}

                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/donasi/echannel/import"
                              )}`}
                            >
                              <NavLink className="menu-link" to="/donasi/echannel/import">
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">Import E-Channel</span>
                              </NavLink>
                            </li>
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/donasi/echannel/kurban"
                              )}`}
                            >
                              <NavLink className="menu-link" to="/donasi/echannel/kurban">
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">Import E-Channel Kurban</span>
                              </NavLink>
                            </li>
                            {/* <li className={`menu-item ${getMenuItemActive('/donasi/echannel/confirmation')}`}>
                                                        <NavLink className="menu-link" to="/donasi/echannel/confirmation">
                                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                            <span className="menu-text">Konfirmasi E-Channel</span>
                                                        </NavLink>
                                                    </li> */}
                            {/*end::3 Level*/}
                          </>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {/*Counter*/}
                {RoleChecker("Read", "list_donasi") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donasi/counter"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/donasi/counter">
                      <span className="menu-text">Konter</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        <li className={`menu-item ${getMenuItemActive("/donasi/counter/list")}`}>
                          <NavLink className="menu-link" to="/donasi/counter/list">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Daftar Konter</span>
                          </NavLink>
                        </li>
                        {RoleChecker("Create Donation", "detail_donasi") && !localStorage.getItem('bannedDonation') && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/counter/new")}`}>
                            <NavLink className="menu-link" to="/donasi/counter/new">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Input</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Create Donation", "detail_donasi") && !localStorage.getItem('bannedDonation') && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/counter/new-v2")}`}>
                            <NavLink className="menu-link" to="/donasi/counter/new-v2">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Input v2</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Read", "collect_donasi_konter") && (
                          <li
                            className={`menu-item ${getMenuItemActive("/donasi/counter/collect")}`}
                          >
                            <NavLink className="menu-link" to="/donasi/counter/collect">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Collect</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Read", "rekap_konter") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/counter/recap")}`}>
                            <NavLink className="menu-link" to="/donasi/counter/recap">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Rekapan Konter</span>
                            </NavLink>
                          </li>
                        )}
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                )}

                {/* KONFIRMASI DONASI */}
                {RoleChecker("Read", "konfirmasi_donasi") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donasi/confirmation"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/donasi/confirmation">
                      <span className="menu-text">Konfirmasi Donasi</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        {RoleChecker("Read", "konfirmasi_donasi") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/confirmation")}`}>
                            <NavLink className="menu-link" to="/donasi/confirmation">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Edit", "konfirmasi_donasi") && (
                          <li
                            className={`menu-item ${getMenuItemActive("/donasi/confirmation/new")}`}
                          >
                            <NavLink className="menu-link" to="/donasi/confirmation/new">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Input</span>
                            </NavLink>
                          </li>
                        )}
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                )}

                {/* KONFIRMASI ECHANNEL */}
                {RoleChecker("Read", "konfirmasi_donasi_echannel") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donasi/echannel/confirmation"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/echannel/confirmation">
                      <span className="menu-text">Konfirmasi E-Channel</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        {RoleChecker("Read", "konfirmasi_donasi_echannel") && (
                          <li
                            className={`menu-item ${getMenuItemActive("/echannel/confirmation")}`}
                          >
                            <NavLink className="menu-link" to="/donasi/echannel/confirmation">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Create", "konfirmasi_donasi_echannel") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/echannel/confirmation/new"
                            )}`}
                          >
                            <NavLink className="menu-link" to="/donasi/echannel/confirmation/new">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Input</span>
                            </NavLink>
                          </li>
                        )}
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                )}

                {/* Rekonsiliasi DONASI */}
                {RoleChecker("Read", "rekonsiliasi_donasi") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donasi/reconcile"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/donasi/reconcile">
                      <span className="menu-text">Rekonsiliasi Donasi</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        {RoleChecker("Read", "rekonsiliasi_donasi") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/reconcile")}`}>
                            <NavLink className="menu-link" to="/donasi/reconcile">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Edit", "rekonsiliasi_donasi") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/reconcile/new")}`}>
                            <NavLink className="menu-link" to="/donasi/reconcile/new">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Input</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Edit", "rekonsiliasi_donasi") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/reconcile/cash")}`}>
                            <NavLink className="menu-link" to="/donasi/reconcile/cash">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Data Proses Tunai</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Edit", "rekonsiliasi_donasi") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/reconcile/non-cash")}`}>
                            <NavLink className="menu-link" to="/donasi/reconcile/non-cash">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Data Proses Non Tunai</span>
                            </NavLink>
                          </li>
                        )}
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                )}

                {/* Settlement */}
                {RoleChecker("Read Menu", "settlement") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/donasi/settlement")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/donasi/settlement">
                      <span className="menu-text">Settlement</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "penjemputan") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/donasi/pickup")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/donasi/pickup">
                      <span className="menu-text">Penjadwalan Jemput</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        <li className={`menu-item ${getMenuItemActive("/donasi/pickup")}`}>
                          <NavLink className="menu-link" to="/donasi/pickup">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Daftar</span>
                          </NavLink>
                        </li>
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                )}

                {(RoleChecker("Read", "pengaturan_konsolidasi_email") ||
                  RoleChecker("Read", "status_konsolidasi_email") ||
                  RoleChecker("Read", "cetak_konsolidasi")) && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donasi/konsolidasi"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/donasi/konsolidasi">
                      <span className="menu-text">Konsolidasi</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        {RoleChecker("Read", "pengaturan_konsolidasi_email") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/donasi/konsolidasi/master-konsolidasi"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/donasi/konsolidasi/master-konsolidasi"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Pengaturan Konsolidasi Email</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Read", "status_konsolidasi_email") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/donasi/konsolidasi/notifikasi-konsolidasi"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/donasi/konsolidasi/notifikasi-konsolidasi"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Status Konsolidasi Email</span>
                            </NavLink>
                          </li>
                        )}

                        {RoleChecker("Read", "cetak_konsolidasi") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/donasi/konsolidasi/print-konsolidasi"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/donasi/konsolidasi/print-konsolidasi"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Cetak Konsolidasi</span>
                            </NavLink>
                          </li>
                        )}
                        {RoleChecker("Read", "consolidation_job") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/donasi/consolidation-job"
                            )}`}
                          >
                            <NavLink className="menu-link" to="/donasi/consolidation-job">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Job Konsolidasi</span>
                            </NavLink>
                          </li>
                        )}
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                )}

                {/* VOID */}
                {RoleChecker("Read", "void_donasi") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/donasi/void")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/donasi/void">
                      <span className="menu-text">Void</span>
                    </NavLink>
                  </li>
                )}

                {/* RECAP */}
                {RoleChecker("Read", "rekap_donasi") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/donasi/recap")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/donasi/recap">
                      <span className="menu-text">Rekapan</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        {RoleChecker("Read", "rekap_donasi") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/recap")}`}>
                            <NavLink className="menu-link" to="/donasi/recap">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Rekap Donasi</span>
                            </NavLink>
                          </li>
                        )}
                        {/*end::3 Level*/}
                      </ul>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}
                        {RoleChecker("Read", "rekap_donasi") && (
                          <li className={`menu-item ${getMenuItemActive("/donasi/collect/recap")}`}>
                            <NavLink className="menu-link" to="/donasi/collect/recap">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Rekap Collector</span>
                            </NavLink>
                          </li>
                        )}
                        {/*end::3 Level*/}
                      </ul>
                    </div>
                  </li>
                )}

                {/* <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive("/donasi/kurban")}`}
                  data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link menu-toggle" to="/donasi/kurban/kurban-list">
                    <span className="menu-text">Kurban</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                    <ul className="menu-subnav">
                      {RoleChecker("Read", "daftar_kurban") && (
                        <li
                          className={`menu-item ${getMenuItemActive("/donasi/kurban/kurban-list")}`}
                        >
                          <NavLink className="menu-link" to="/donasi/kurban/kurban-list">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Daftar Kurban</span>
                          </NavLink>
                        </li>
                      )}

                      {RoleChecker("Read", "hewan_kurban") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/donasi/kurban/hewan-kurban"
                          )}`}
                        >
                          <NavLink className="menu-link" to="/donasi/kurban/hewan-kurban">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Hewan Kurban</span>
                          </NavLink>
                        </li>
                      )}

                      {RoleChecker("Read", "hewan_kurban_campaign") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/donasi/kurban/hewan-kurban-campaign"
                          )}`}
                        >
                          <NavLink className="menu-link" to="/donasi/kurban/hewan-kurban-campaign">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Hewan Kurban Campaign</span>
                          </NavLink>
                        </li>
                      )}
                      {RoleChecker("Read", "cicilan_kurban") && (
                        <li className={`menu-item ${getMenuItemActive("/donasi/cilkur")}`}>
                          <NavLink className="menu-link" to="/donasi/cilkur">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Cicilan Kurban</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li> */}

                {RoleChecker("Read", "daftar_wakaf") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donasi/wakaf/wakaf-list"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/donasi/wakaf/wakaf-list">
                      <span className="menu-text">Daftar Wakaf</span>
                    </NavLink>
                  </li>
                )}

                {/* RECAP */}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/donasi/interaksi-donatur"
                  )}`}
                  data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/donasi/interaksi-donatur">
                    <span className="menu-text">Interaksi Donasi (Coming Soon)</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        )}

        {/*DONATUR*/}
        {RoleChecker("Read", "daftar_donatur") && (
          // <li className={`menu-item menu-item-rel ${getMenuItemActive('/donatur')}`}>
          //     <NavLink className="menu-link" to="/donatur">
          //         <span className="menu-text">Donatur</span>
          //         {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
          //     </NavLink>
          // </li>
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/donatur")}`}
          >
            <NavLink className="menu-link menu-toggle" to="/donatur">
              <span className="menu-text">Donatur</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {RoleChecker("Read", "daftar_donatur") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/donatur")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/donatur">
                      <span className="menu-text">Daftar Donatur</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "donor_import") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/donatur-import"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/donatur-import">
                      <span className="menu-text">Import Donatur</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "daftar_donatur") && (
                  <>
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/donatur/double"
                      )}`}
                      data-menu-toggle="hover"
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link menu-toggle" to="/donasi/double">
                        <span className="menu-text">Double Donatur</span>
                        <i className="menu-arrow" />
                      </NavLink>

                      <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                        <ul className="menu-subnav">
                          {/*begin::3 Level*/}
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/donatur/double/donatur-name"
                            )}`}
                          >
                            <NavLink className="menu-link" to="/donatur/double/donatur-name">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                Daftar Donatur Double Berdasarkan Nama
                              </span>
                            </NavLink>
                          </li>
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/donatur/double/donatur-rekening"
                            )}`}
                          >
                            <NavLink className="menu-link" to="/donatur/double/donatur-rekening">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                Daftar Donatur Double Berdasarkan Rekening
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* KURBAN */}
        {RoleChecker("Read", "donasi") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/qurban")}`}
          >
            <NavLink className="menu-link menu-toggle" to="/qurban">
              <span className="menu-text">Kurban</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {RoleChecker("Read", "daftar_kurban") && (
                  <li
                    className={`menu-item ${getMenuItemActive("/qurban/kurban/kurban-list")}`}
                  >
                    <NavLink className="menu-link" to="/qurban/kurban/kurban-list">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Daftar Kurban</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "hewan_kurban") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/qurban/kurban/hewan-kurban"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/qurban/kurban/hewan-kurban">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Hewan Kurban</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "hewan_kurban_campaign") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/qurban/kurban/hewan-kurban-campaign"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/qurban/kurban/hewan-kurban-campaign">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Hewan Kurban Campaign</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "cicilan_kurban") && (
                  <li className={`menu-item ${getMenuItemActive("/donasi/cilkur")}`}>
                    <NavLink className="menu-link" to="/donasi/cilkur">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Cicilan Kurban</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "thk_partnership") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/qurban/partnership")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/qurban/partnership">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Partnership</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "thk_period_template") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/qurban/period_template")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/qurban/period_template">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Tema Laporan</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "thk_channel_report") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/qurban/thk_channel_report")}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/qurban/thk_channel_report">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Tema Channel Laporan</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/*PAGE COLLECTOR ONLY*/}
        {/* {collector_roles.includes(user.role) && (
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/collector')}`}>
                    <NavLink className="menu-link menu-toggle" to="/collector">
                        <span className="menu-text">Collector</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">

                            begin::2 Level
                            <li className={`menu-item ${getMenuItemActive('/collector/list')}`}>
                                <NavLink className="menu-link" to="/collector">
                                    <span className="menu-text">List</span>
                                </NavLink>
                            </li>

                            <li className={`menu-item ${getMenuItemActive('/collector/collect')}`}>
                                <NavLink className="menu-link" to="/collector/collect">
                                    <span className="menu-text">Collect</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
            )} */}

        {/*PAGE COLLECTOR 2 ONLY*/}
        {RoleChecker("Read", "collector") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/collector"
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to="/collector">
              <span className="menu-text">Collector</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive("/collector/list")}`}>
                  <NavLink className="menu-link" to="/collector">
                    <span className="menu-text">List</span>
                  </NavLink>
                </li>

                {RoleChecker("Collect", "collector") && (
                  <li className={`menu-item ${getMenuItemActive("/collector/collect")}`}>
                    <NavLink className="menu-link" to="/collector/collect">
                      <span className="menu-text">Collect</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/*LAPORAN PAGE*/}
        {/*{laporan_roles.includes(user.role) && (*/}
        {/*    <li className={`menu-item menu-item-rel ${getMenuItemActive('/laporan')}`}>*/}
        {/*        <NavLink className="menu-link" to="/laporan">*/}
        {/*            <span className="menu-text">Laporan</span>*/}
        {/*            {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}*/}
        {/*        </NavLink>*/}
        {/*    </li>*/}
        {/*)}*/}

        {/*PAGE CHECKER ONLY*/}
        {/* {checker_roles.includes(user.role) && (
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/checker')}`}>
                    <NavLink className="menu-link" to="/checker">
                        <span className="menu-text">Checker</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
            )} */}
        {/* PAGE CHECKER 2 */}
        {RoleChecker("Read", "checker") && (
          <li data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                "/checker"
              )}`}
          >
            <NavLink className="menu-link menu-toggle" to="/checker">
              <span className="menu-text">Checker</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive("/checker/list")}`}>
                  <NavLink className="menu-link" to="/checker">
                    <span className="menu-text">List</span>
                  </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/checker/check")}`}>
                  <NavLink className="menu-link" to="/checker/check">
                    <span className="menu-text">Check</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        )}

        {/*MY PAGE*/}
        {/*<li className={`menu-item menu-item-rel ${getMenuItemActive('/my-page')}`}>*/}
        {/*    <NavLink className="menu-link" to="/my-page">*/}
        {/*        <span className="menu-text">My Page</span>*/}
        {/*        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}*/}
        {/*    </NavLink>*/}
        {/*</li>*/}
        {/*end::1 Level*/}

        {/*NavLink Master Data*/}
        {RoleChecker("Read", "master_data") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/master-data"
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to="/master-data">
              <span className="menu-text">Master Data</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                {RoleChecker("Read", "branch") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/branchs")}`}>
                    <NavLink className="menu-link" to="/master-data/branchs">
                      <span className="menu-text">Branch</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "user") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/user")}`}>
                    <NavLink className="menu-link" to="/master-data/user">
                      <span className="menu-text">User</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "user") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/log-login")}`}>
                    <NavLink className="menu-link" to="/master-data/log-login">
                      <span className="menu-text">Log Login User</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "user") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/payment")}`}>
                    <NavLink className="menu-link" to="/master-data/payment">
                      <span className="menu-text">Payment</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "bank") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/bank")}`}>
                    <NavLink className="menu-link" to="/master-data/bank">
                      <span className="menu-text">Bank</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "bank_pooling") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/pooling-bank")}`}>
                    <NavLink className="menu-link" to="/master-data/pooling-bank">
                      <span className="menu-text">Bank Pooling</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "fund_type") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/fundtype")}`}>
                    <NavLink className="menu-link" to="/master-data/fundtype">
                      <span className="menu-text">Fund Type</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "checker") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/projects")}`}>
                    <NavLink className="menu-link" to="/master-data/projects">
                      <span className="menu-text">Project</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "project") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/program")}`}>
                    <NavLink className="menu-link" to="/master-data/program">
                      <span className="menu-text">Program</span>
                    </NavLink>
                  </li>
                )}
                {RoleChecker("Read", "template_notifikasi") && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master-data/notifikasi"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/Notifikasi">
                      <span className="menu-text">Notifikasi</span>
                      <i className="menu-arrow" />
                    </NavLink>

                    <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                      <ul className="menu-subnav">
                        {/*begin::3 Level*/}

                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/master-data/notifikasi/template-notifikasi"
                          )}`}
                        >
                          <NavLink
                            className="menu-link"
                            to="/master-data/notifikasi/template-notifikasi"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Template Notifikasi</span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}

                {/* {master_program_roles.includes(user.role) && (
                                <li className={`menu-item ${getMenuItemActive('/master-data/template-notifikasi')}`}>
                                    <NavLink className="menu-link" to="/master-data/template-notifikasi">
                                        <span className="menu-text">Template Notifikasi</span>
                                    </NavLink>
                                </li>
                            )} */}

                {RoleChecker("Read", "master_kategori") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/master-category")}`}>
                    <NavLink className="menu-link" to="/master-data/master-category">
                      <span className="menu-text">Master Kategori</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "department") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/department")}`}>
                    <NavLink className="menu-link" to="/master-data/department">
                      <span className="menu-text">Department</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "kategori_barang") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/goods-category")}`}>
                    <NavLink className="menu-link" to="/master-data/goods-category">
                      <span className="menu-text">Kategori Barang</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "auto_settle") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/auto-settle")}`}>
                    <NavLink className="menu-link" to="/master-data/auto-settle">
                      <span className="menu-text">Auto Settle</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "oauth_app") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/oauth-app")}`}>
                    <NavLink className="menu-link" to="/master-data/oauth-app">
                      <span className="menu-text">OAuth App</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "master_option") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/master-option")}`}>
                    <NavLink className="menu-link" to="/master-data/master-option">
                      <span className="menu-text">Master Option</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "donor_source") && (
                  <li className={`menu-item ${getMenuItemActive("/master-data/donor-source")}`}>
                    <NavLink className="menu-link" to="/master-data/donor-source">
                      <span className="menu-text">Sumber Data Donatur</span>
                    </NavLink>
                  </li>
                )}

                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/master-data/role"
                  )}`}
                  data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link menu-toggle" to="/Role">
                    <span className="menu-text">Role</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                    <ul className="menu-subnav">
                      {RoleChecker("Read", "access_role") && (
                        <li
                          className={`menu-item ${getMenuItemActive("/master-data/access-role")}`}
                        >
                          <NavLink className="menu-link" to="/master-data/access-role">
                            <span className="menu-text">Access Role</span>
                          </NavLink>
                        </li>
                      )}

                      {RoleChecker("Read", "channel_role") && (
                        <li
                          className={`menu-item ${getMenuItemActive("/master-data/channel-role")}`}
                        >
                          <NavLink className="menu-link" to="/master-data/channel-role">
                            <span className="menu-text">Channel Role</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>

                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/master-data/mp-program"
                  )}`}
                  data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link menu-toggle" to="/Role">
                    <span className="menu-text">Mini Program</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                    <ul className="menu-subnav">
                      {RoleChecker("Read", "mp_program") && (
                        <li className={`menu-item ${getMenuItemActive("/master-data/mp-program")}`}>
                          <NavLink className="menu-link" to="/master-data/mp-program">
                            <span className="menu-text">Program Mini Program</span>
                          </NavLink>
                        </li>
                      )}

                      {RoleChecker("Read", "mp_category") && (
                        <li
                          className={`menu-item ${getMenuItemActive("/master-data/mp-category")}`}
                        >
                          <NavLink className="menu-link" to="/master-data/mp-category">
                            <span className="menu-text">Kategori Mini Program</span>
                          </NavLink>
                        </li>
                      )}

                      {RoleChecker("Read", "mp_banner") && (
                        <li className={`menu-item ${getMenuItemActive("/master-data/mp-banner")}`}>
                          <NavLink className="menu-link" to="/master-data/mp-banner">
                            <span className="menu-text">Banner Mini Program</span>
                          </NavLink>
                        </li>
                      )}

                      {RoleChecker("Read", "mp_banner") && (
                        <li className={`menu-item ${getMenuItemActive("/master-data/mp-report")}`}>
                          <NavLink className="menu-link" to="/master-data/mp-report">
                            <span className="menu-text">Report Mini Program</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        )}
        {
          // TODO: 09/19/23 Lanjut dari sini untuk buat menu baru (Copas dari branch, inputnya copas dari donation untuk ambil select nya)
        }
        
        {RoleChecker("Read Menu", "program_project") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/program-project"
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to="/program-project">
              <span className="menu-text">Program Project</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}

                {RoleChecker("Read Project", "project") && (
                  <li className={`menu-item ${getMenuItemActive("/program-project/projects")}`}>
                    <NavLink className="menu-link" to="/program-project/projects">
                      <span className="menu-text">Project</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read Program", "program") && (
                  <li className={`menu-item ${getMenuItemActive("/program-project/program")}`}>
                    <NavLink className="menu-link" to="/program-project/program">
                      <span className="menu-text">Program</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read Migration", "migration") && (
                  <li className={`menu-item ${getMenuItemActive("/program-project/migration")}`}>
                    <NavLink className="menu-link" to="/program-project/migration">
                      <span className="menu-text">Migration</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {RoleChecker("Read", "analisa") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/analysis"
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to="/analysis">
              <span className="menu-text">Analisa</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                {RoleChecker("Read", "analisis_pertumbuhan_donatur") && (
                  <li className={`menu-item ${getMenuItemActive("/analysis/donor-growth")}`}>
                    <NavLink className="menu-link" to="/analysis/donor-growth">
                      <span className="menu-text">Analisis Pertumbuhan Donatur</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "analisis_ranking_donasi_donatur_berdasarkan_nominal") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/analysis/donor-ranking/nominal/total_donasi"
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/analysis/donor-ranking/nominal/total_donasi"
                    >
                      <span className="menu-text">
                        Analisis Ranking Donasi Donatur Berdasarkan Nominal
                      </span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "analisis_ranking_donasi_donatur_berdasarkan_item_donasi") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/analysis/donor-ranking/donation-item/frekuensi_donasi"
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/analysis/donor-ranking/donation-item/frekuensi_donasi"
                    >
                      <span className="menu-text">
                        Analisis Ranking Donasi Donatur Berdasarkan Item Donasi
                      </span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "analisis_keaktifan_donatur") && (
                  <li className={`menu-item ${getMenuItemActive("/analysis/donor-active")}`}>
                    <NavLink className="menu-link" to="/analysis/donor-active">
                      <span className="menu-text">Analisis Keaktifan Donatur</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "analisis_ranking_perolehan_konter") && (
                  <li className={`menu-item ${getMenuItemActive("/analysis/counter-ranking")}`}>
                    <NavLink className="menu-link" to="/analysis/counter-ranking">
                      <span className="menu-text">
                        Analisis Perolehan Berdasarkan Konter, Gerai dan Kantor Kas
                      </span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "analisis_data_donatur_berdasarkan_jenis_donasi") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/analysis/donor-analysis/donation-type"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/analysis/donor-analysis/donation-type">
                      <span className="menu-text">
                        Analisis Data Donatur Berdasarkan Jenis Donasi
                      </span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read", "analisis_data_donatur_berdasarkan_wilayah") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/analysis/donor-analysis/donor-area"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/analysis/donor-analysis/donor-area">
                      <span className="menu-text">Analisis Data Donatur Berdasarkan Wilayah</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {RoleChecker("Read Menu", "export") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/export")}`}
          >
            <NavLink className="menu-link menu-toggle" to="/export/list">
              <span className="menu-text">Export</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                {RoleChecker("Read Daftar Export", "list_export") && (
                  <li className={`menu-item ${getMenuItemActive("/export/list")}`}>
                    <NavLink className="menu-link" to="/export/list">
                      <span className="menu-text">Daftar Export</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read Log Export", "log_export") && (
                  <li className={`menu-item ${getMenuItemActive("/export/log-list")}`}>
                    <NavLink className="menu-link" to="/export/log-list">
                      <span className="menu-text">Log Export</span>
                    </NavLink>
                  </li>
                )}

                {RoleChecker("Read Export Donation", "export_dona") && (
                  <li className={`menu-item ${getMenuItemActive("/export/donation")}`}>
                    <NavLink className="menu-link" to="/export/donation">
                      <span className="menu-text">Export Donation</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

         {/* {RoleChecker("Read", "checker") && (
            <li className={`menu-item menu-item-rel ${getMenuItemActive("/aol-config")}`}>
              <NavLink className="menu-link" to="/aol-config">
                <span className="menu-text">AOL Config</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>
          )} */}

          {/* [AY] Hide Config */}
          {/* {RoleChecker("Read", "checker") && (
           <li 
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/config")}`}>
            <NavLink className="menu-link menu-toggle" to="/config">
              <span className="menu-text">Config</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">   
              <li
                className={`menu-item ${getMenuItemActive("/config/aol-config")}`}
              >
                <NavLink className="menu-link" to="/config/aol-config">
                  <span className="menu-text">AOL Config</span>
                </NavLink>
              </li>
            
            
              <li
                className={`menu-item ${getMenuItemActive(
                  "/config/aol-big-query"
                )}`}
              >
                <NavLink className="menu-link" to="/config/aol-big-query">
                  <span className="menu-text">AOL Big Query</span>
                </NavLink>
              </li>
                
              </ul>
            </div>
          </li> 
          )} */}
        

        {/* {RoleChecker("Read", "daftar_export") && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive("/export-list")}`}>
            <NavLink className="menu-link" to="/export-list">
              <span className="menu-text">Daftar Export</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )} */}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
