import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './BankPoolingUIHelpers';

const BankPoolingUIContext = createContext();

export function useBankPoolingUIContext() {
    return useContext(BankPoolingUIContext);
}

export const BankPoolingUIConsumer = BankPoolingUIContext.Consumer;

export function BankPoolingUIProvider({ bankPoolingUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initBankPooling = {
        name: '',
        branch_uuid: "",
        bank_uuid: "",
        account_number: '',
        db: '',
        account_type: '',
        account_number_coa: ''
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initBankPooling,
        newBankPoolingButtonClick: bankPoolingUIEvents.newBankPoolingButtonClick,
        openEditBankPoolingPage: bankPoolingUIEvents.openEditBankPoolingPage,
    };

    return (
        <BankPoolingUIContext.Provider value={value}>
            {children}
        </BankPoolingUIContext.Provider>
    );

}
