import axios from 'axios';
import { DonationURL } from '../../../../config/UrlEndPoint';
import { URL } from "../../../Donatur/_redux/donatur/donaturCrud";

export const DONATION_URL = `${DonationURL}donation`;

// Get Donation Payment
export function getDonationPayment(queryParams) {
    return axios.post(`${DONATION_URL}/v2/donation_payment`, queryParams);
}

// items => filtered/sorted result
export function findDonations(queryParams) {
    return axios.post(`${DONATION_URL}/find`, queryParams);
}

//Get By ID
export function getDonationById(donationId) {
    return axios.get(`${DONATION_URL}/${donationId}`);
}

//Get By ID with query
export function getDonationByIdQuery(donationId) {
    return axios.get(`${DONATION_URL}/${donationId}/query`);
}

//Get By Donation HID
export function getDonationByHid(donationHid) {
    return axios.get(`${DONATION_URL}/dona_hid/${donationHid}`);
}

//Create Donation Counter
export function createDonation(donationData) {
    return axios.post(`${DONATION_URL}`, donationData);
}

export function createDonationV2(donationData) {
    return axios.post(`${DONATION_URL}/v2`, donationData);
}

export function updateDonationV2(id, donationData) {
    return axios.put(`${DONATION_URL}/v2/${id}`, donationData);
}

export function updateRequestStatusDonationV2(id, donationData) {
    return axios.put(`${DONATION_URL}/v2/status/${id}`, donationData);
}

//Post Donation Item
export function putDonationItem(donationItem, donationId) {
    return axios.put(`${DONATION_URL}/donationitem/${donationId}`, donationItem);
}

//Change Channel
export function updateDonationChannel(data, uuid) {
    return axios.put(`${DONATION_URL}/update_donation_channel`, { ...data, uuid });
}


// URL update batch data donatur
export function updateDonatur(payload) {
    return axios.put(`${DONATION_URL}/update_donor`, payload);
}

// URL REQUEST VOID
export function requestVoid(data) {
    return axios.put(`${DONATION_URL}/void/request`, data);
}
// Request force void if status settle
export function requestVoidSettle(data) {
    return axios.put(`${DONATION_URL}/force_void_settle/request`, data);
}

//Get By ID
export function getKurbanList() {
    return axios.get(`${DonationURL}livestock`);
}

//Generate EXCEL
export function generateReport(payload) {
    return axios.post(`${DONATION_URL}/report`, payload, { responseType: 'arraybuffer' });

}

// UPDATE SETTLE
export function updateStatus(id) {
    return axios.put(`${DONATION_URL}/settle/${id}`);
}

export function sendNotification(id, email, hp, whatsapp) {
    return axios.post(`${DONATION_URL}/send_notification`, { id: id, email: email, hp: hp, whatsapp: whatsapp })
}

export function exportDonation(value) {
    return axios.post(`${DONATION_URL}/report`, value)
}

//Get By ID
export function getDonationNotificationById(donationId) {
    return axios.get(`${DONATION_URL}/notification/${donationId}`);
}

//Change Date
export function updateDonationDate(date, uuid) {
    return axios.put(`${DONATION_URL}/update_donation_date`, { trx_date: date, uuid });
}

export function updateDonationDepartment(uuid, department_uuid) {
    return axios.put(`${DONATION_URL}/update_donation_department`, { uuid, department_uuid });
}

export function exportDonationMitra(value) {
    return axios.post(`${DONATION_URL}/download_export_donation_mitra`, value, { responseType: 'arraybuffer' })
}

export function resendJournalAol(payload) {
    return axios.post(`${DONATION_URL}/resend_journal_aol`, payload);
}
