import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { useBankPoolingUIContext } from './BankPoolingUIContext';
import BankPoolingTable from './bank-pooling-table/BankPoolingTable';
import { BankPoolingFilter } from './bank-pooling-table/bank-pooling-filter/BankPoolingFilter'
import { RoleChecker } from '../../../../RoleChecker';

export const BankPoolingCard = () => {

	const bankPoolingUIContext = useBankPoolingUIContext();
	const bankPoolingUIProps = useMemo(() => {
		return {
			newBankPoolingButtonClick: bankPoolingUIContext.newBankPoolingButtonClick,
		};
	}, [bankPoolingUIContext]);

	return (
		<Card>
			<CardHeader title="Bank Pooling List">
				<CardHeaderToolbar>
					{RoleChecker('Create', 'bank_pooling') && <button
						type="button"
						className="btn btn-primary"
						onClick={bankPoolingUIProps.newBankPoolingButtonClick}
					>Bank Pooling Baru
					</button>}
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<BankPoolingFilter />
				<BankPoolingTable />
			</CardBody>
		</Card>
	);
};
