import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './PaymentUIHelpers'

const PaymentUIContext = createContext();

export function usePaymentUIContext() {
    return useContext(PaymentUIContext);
}

export const PaymentUIConsumer = PaymentUIContext.Consumer;

export function PaymentUIProvider({ paymentUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initPayment = {
        name: '',
        type: '',
        merchant_id: '',
        bank_pooling_uuid: '',
        channel_uuid: '',
        branch_uuid: '',
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initPayment,
        newPaymentButtonClick: paymentUIEvents.newPaymentButtonClick,
        openEditPaymentPage: paymentUIEvents.openEditPaymentPage,
        openDeletePaymentPage: paymentUIEvents.openDeletePaymentPage,
    };

    return (
        <PaymentUIContext.Provider value={value}>
            {children}
        </PaymentUIContext.Provider>
    );

}
