import React, { useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useBankPoolingUIContext } from '../../BankPoolingUIContext';
import { initialFilter } from '../../BankPoolingUIHelpers';
import * as BranchReq from '../../../../_redux/branchs/branchCrud'
import * as BankReq from '../../../../_redux/bank/bankCrud'

const prepareFilter = (queryParams, values) => {
	const { account_number, branch_uuid, bank_uuid } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	//add to Object filter
	filter.account_number = account_number ?? '';
	filter.branch_uuid = branch_uuid ?? '';
	filter.bank_uuid = bank_uuid ?? '';
	newQueryParams.filter = filter;
	return newQueryParams;
};

export const BankPoolingFilter = ({ listLoading }) => {
	// BankPooling UI Context
	const bankPoolingUIContext = useBankPoolingUIContext();
	const bankPoolingUIProps = useMemo(() => {
		return {
			setQueryParams: bankPoolingUIContext.setQueryParams,
			queryParams: bankPoolingUIContext.queryParams,
			filterType: bankPoolingUIContext.filterType,
		};
	}, [bankPoolingUIContext]);

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(bankPoolingUIProps.queryParams, values);
		if (!isEqual(newQueryParams, bankPoolingUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			bankPoolingUIProps.setQueryParams(newQueryParams);
		}
	};

	const [bankList, setBankList] = useState([])
	const [branchList, setBranchList] = useState([])

	const getBranch = () => {
		BranchReq.getBranchAll().then((response) => {
			setBranchList(response.data.data)
		})
	}

	const getBank = () => {
		BankReq.getBankAll().then((response) => {
			setBankList(response.data.data)
		})
	}


	useEffect(() => {
		getBank()
		getBranch()
	}, [])

	return (
		<Formik initialValues={initialFilter.filter} onSubmit={(values) => {
			applyFilter(values);
		}}
		>{({ values, handleSubmit, handleBlur, setFieldValue }) => (
			<form onSubmit={handleSubmit} className="form form-label-right">
				<div className="form-group row">
					<div className="col-lg-4">
						<input
							type="text"
							className="form-control"
							name="account_number"
							placeholder="Search"
							onBlur={handleBlur}
							value={values.account_number}
							onChange={(e) => {
								setFieldValue('account_number', e.target.value);
								handleSubmit();
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Nomor Akun
						</small>
					</div>
					<div className="col-lg-4">
						<select
							name="branch_uuid"
							className="form-control"
							placeholder="Text"
							label="Cabang"
							onChange={(e) => {
								setFieldValue('branch_uuid', e.target.value);
								handleSubmit();
							}}
						>
							<option value="">ALL</option>
							{
								branchList.map((item) => {
									return <option key={item.uuid} value={item.uuid}>{item.name}</option>
								})
							}
						</select>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Cabang
						</small>
					</div>
					<div className="col-lg-4">
						<select
							name="bank_uuid"
							className="form-control"
							placeholder="Text"
							label="Bank"
							onChange={(e) => {
								setFieldValue('bank_uuid', e.target.value);
								handleSubmit();
							}}
						>
							<option value="">ALL</option>
							{
								bankList.map((item) => {
									return <option key={item.uuid} value={item.uuid}>{item.name}</option>
								})
							}
						</select>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Bank
						</small>
					</div>
				</div>
			</form>
		)}
		</Formik>

	);
};

