/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers';

const status = ["REQUEST_VOID", "VOID", "SETTLE", "COLLECT"]
const access = ['CustomerCare', 'DataProses', 'Fundraiser', 'FundCollCheck', 'KepalaCabang', 'SuperAdmin']
export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { openDetailDonationPage, openDialogVoid, role, allowRequestVoid },
) => {
    const isPaymentFound = row.payments.some(v => v.payment_type !== null);
    const toLink = (channel_type) => {
        if ((channel_type).toString().toUpperCase() === "ECHANNEL" && !isPaymentFound) {
            return `/donasi/echannel/${row.uuid}/detail`
        };

        if ((channel_type).toString().toUpperCase() === "ECHANNEL" && isPaymentFound) {
            return `/donasi/echannel/${row.uuid}/detail-v2`
        };
        
        if ((channel_type).toString().toUpperCase() !== "ECHANNEL" && !isPaymentFound) {
            return `/donasi/${row.uuid}/detail`
        }

        if ((channel_type).toString().toUpperCase() !== "ECHANNEL" && isPaymentFound) {
            return `/donasi/${row.uuid}/detail-v2`
        }
    }

    return (
        <>
            {
                <OverlayTrigger
                    overlay={<Tooltip id="donation-edit-tooltip">View Detail</Tooltip>}
                >
                    <Link to={toLink(row.channel_type)} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                            />
                        </span>
                    </Link>
                    {/* <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        // onClick={() => openDetailDonationPage(row.uuid)}
                    >{row.id}
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                            />
                        </span>
                    </a> */}
                </OverlayTrigger>
            }


            {!status.includes(row.status) && !row.collect_batch_uuid && allowRequestVoid && (
                <OverlayTrigger
                    overlay={<Tooltip id="products-delete-tooltip">Ajukan Void</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={() => {
                            openDialogVoid(row.uuid, row.donation_hid)
                        }}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                        </span>
                    </a>
                </OverlayTrigger>
            )}
        </>
    )
}
