/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";

export const ActionsColumnFormatter = (
    row,
) => {
    return (
        <div className="text-center">
            <OverlayTrigger
                overlay={<Tooltip id="donation-edit-tooltip">View Detail</Tooltip>}
            >
                <Link to={`/donasi/${row.uuid}/detail`} className="text-light">
                    <button className="btn btn-sm btn-primary mb-1">Detail Donasi
                    </button>
                </Link>
            </OverlayTrigger>
        </div>
    )
}
