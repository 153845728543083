import React, {  useMemo } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useBankUIContext } from '../../BankUIContext';
import { initialFilter } from '../../BankUIHelpers';

const prepareFilter = (queryParams, values) => {
	const { searchName } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	//add to Object filter
	filter.name = searchName ?? '';
	newQueryParams.filter = filter;
	return newQueryParams;
};

export const BankFilter = ({ listLoading }) => {
	// Bank UI Context
	const bankUIContext = useBankUIContext();
	const bankUIProps = useMemo(() => {
		return {
			setQueryParams: bankUIContext.setQueryParams,
			queryParams: bankUIContext.queryParams,
			filterType: bankUIContext.filterType,
		};
	}, [bankUIContext]);

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(bankUIProps.queryParams, values);
		if (!isEqual(newQueryParams, bankUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			bankUIProps.setQueryParams(newQueryParams);
		}
	};

	return (
		<Formik initialValues={initialFilter.filter} onSubmit={(values) => {
			applyFilter(values);
		}}
		>{({ values, handleSubmit, handleBlur, setFieldValue }) => (
			<form onSubmit={handleSubmit} className="form form-label-right">
				<div className="form-group row">
					<div className="col-lg-4">
						<input
							type="text"
							className="form-control"
							name="searchName"
							placeholder="Search"
							onBlur={handleBlur}
							value={values.searchName}
							onChange={(e) => {
								setFieldValue('searchName', e.target.value);
								handleSubmit();
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Name
						</small>
					</div>
				</div>
			</form>
		)}
		</Formik>

	);
};

