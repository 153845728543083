import * as requestFromServer from './reconcileCrud';
import { reconcileSlice, callTypes } from './reconcileSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = reconcileSlice;

export const fetchSettlement = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getReconcileV2(queryParams)
		.then(response => {
			const { data: { total_items, items } } = response.data;
			dispatch(actions.reconcilesFetched({ total_items, items }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchReconciles = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.findReconcileQuery(queryParams)
		.then(response => {
			const { data: { total_items, items } } = response.data;
			dispatch(actions.reconcilesFetched({ total_items, items }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

// Ambil 1 data berdasarkan id untuk reconcile-edit data
export const fetchReconcile = (id) => dispatch => {
	if (!id) {
		return dispatch(actions.reconcileFetched({ reconcileData: undefined }));
	}

	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.getReconcileId(id)
		.then(response => {
			// alias data to reconcile;
			const { data: reconcile } = response.data;
			dispatch(actions.reconcileFetched({ reconcileData: reconcile }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

// ACTION UPLOAD PAYMENT FILE
export const createReconcile = (data) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.createReconcile(data)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const createReconcileV2 = (data) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.createSettlementV2(data)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

// ACTION UPLOAD PAYMENT FILE
export const createReconcileDraft = (data) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.createReconcileDraft(data)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const updateReconcile = (data) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateReconcile(data)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};


export const deleteReconcile = (uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.deleteReconcile(uuid)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};


export const editReconcile = (data) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.editReconcile(data)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};
export const editDraftReconcile = (data) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.editDraftReconcile(data)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const manualReconcile = (uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.manualReconcile(uuid)
		.then(response => {
			dispatch(actions.reconcileReload);
			return response.data.message
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};