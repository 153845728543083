import React, { useEffect, useMemo } from "react";
import { Modal } from 'react-bootstrap';
import { PaymentForm } from '../payment-dialog/PaymentForm';
import { PaymentHeader } from '../payment-dialog/PaymentHeader';
import * as actions from '../../../_redux/payment/paymentAction';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../../_metronic/_helpers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usePaymentUIContext } from "../PaymentUIContext";

export const PaymentDialog = ({ show, id, onHide }) => {
    // Payment UI Context
    const paymentUIContext = usePaymentUIContext();
    const paymentUIProps = useMemo(() => {
        return {
            initPayment: paymentUIContext.initPayment,
        };
    }, [paymentUIContext]);

    const dispatch = useDispatch()
    const handleSubmitPayment = (body) => {
        if (!id) {
            dispatch(actions.createPayment(body)).then((response) => {
                toast.success(response.message, toastOption);
                dispatch(actions.fetchPayment()).then(onHide);
            });

        } else {
            dispatch(actions.updatePayment(body, id)).then((response) => {
                toast.success(response.message, toastOption);
                dispatch(actions.fetchPayment()).then(onHide);
            });
        }
    };

    const { paymentForEdit } = useSelector(
        (state) => ({
            actionsLoading: state.payment.actionsLoading,
            paymentForEdit: state.payment.paymentForEdit,
            error: state.payment.error
        }),
        shallowEqual,
    );

    useEffect(() => {
        // get payment id
        if (id) dispatch(actions.fetchPaymentById(id));

    }, [id, dispatch]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="modal-sizes-title-lg"
        >
            <PaymentHeader id={id} />
            <PaymentForm 
                payment={id ? paymentForEdit || paymentUIProps.initPayment : paymentUIProps.initPayment}
                onHide={onHide} 
                onSubmit={handleSubmitPayment}
            />
        </Modal>
    );
}