import React from 'react';
import { BankMutationUIProvider } from './BankMutationUIContext';
import { BankMutationCard } from './BankMutationCard';

export function BankMutationPage({ history }) {

    const uiEvent = {
        importBankMutationButtonClick: () => {
            history.push('/mastd-bank/bank-mutation/import');
        },
        detailBankMutationButtonClick: (id) => {
            history.push(`/mastd-bank/bank-mutation/${id}/detail`);
        },
    };

    return (
        <BankMutationUIProvider uiEvent={uiEvent}>
            <BankMutationCard />
        </BankMutationUIProvider>
    );
}
