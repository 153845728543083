export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 }
];

export const initialFilter = {
    filter: {
        account_number: "",
        branch_uuid: "",
        bank_uuid: ""
    },
    pageNumber: 1,
    pageSize: 10
};
