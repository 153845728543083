import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/bank/bankAction';
import * as uiHelpers from '../BankUIHelpers';

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { useBankUIContext } from '../BankUIContext';
import { RoleChecker } from '../../../../../RoleChecker';
// import { ToastContainer } from 'react-toastify';

const BankTable = (props) => {
    // Bank UI Context
    const bankUIContext = useBankUIContext();
    const bankUIProps = useMemo(() => {
        return {
            queryParams: bankUIContext.queryParams,
            setQueryParams: bankUIContext.setQueryParams,
            openEditBankPage: bankUIContext.openEditBankPage,
        };
    }, [bankUIContext]);


    // Getting curret state of bank list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.banks }),
        shallowEqual,
    );

    const { totalCount, entities, listLoading } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {

        //Requset ke Branch Action
        dispatch(actions.fetchBanks(bankUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankUIProps.queryParams, dispatch]);

    const allowEdit = RoleChecker('Edit', 'bank')


    // Table columns
    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditBankPage: bankUIProps.openEditBankPage,
                allowEdit
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: bankUIProps.queryParams.pageSize,
        page: bankUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    bankUIProps.setQueryParams,
                                )}
                                // selectRow={getSelectRow({
                                //   entities,
                                //   ids: productsUIProps.ids,
                                //   setIds: productsUIProps.setIds,
                                // })}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};

export default BankTable;
