import React from 'react';
import { PaymentCard } from './PaymentCard';
import { PaymentUIProvider } from './PaymentUIContext';
import { PaymentDialog } from './payment-dialog/PaymentDialog';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '../../../../PrivateRoute';
import { RoleChecker } from '../../../../RoleChecker';
import { PaymentDeleteDialog } from './payment-dialog/PaymentDeleteDialog';

export function PaymentPage({ history }) {
    const paymentUIEvents = {
        newPaymentButtonClick: () => {
            history.push('/payment/new');
        },
        openEditPaymentPage: (id) => {
            history.push(`/payment/${id}/edit`);
        },
        openDeletePaymentPage: (id) => {
            history.push(`/payment/${id}/delete`);
        },
    };

    return (
        <PaymentUIProvider paymentUIEvents={paymentUIEvents}>
            {/* Create */}
            <PrivateRoute 
                role={RoleChecker('Create', 'bank')} 
                component={PaymentDialog}
                path="/payment/new" 
            />

            <Route path="/payment/new">
                {({ history, match }) => (
                    <PaymentDialog
                        show={match != null}
                        onHide={() => history.push('/payment')}
                    />
                )}
            </Route> 

            {/* Edit */}
            <PrivateRoute role={RoleChecker('Edit', 'bank')} component={PaymentDialog}
                path="/payment/:id/edit" />

            <Route path="/payment/:id/edit">
                {({ history, match }) => (
                    <PaymentDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => history.push('/payment')}
                    />
                )}
            </Route> 

            {/* Delete */}
            <PrivateRoute role={RoleChecker('Edit', 'bank')} component={PaymentDeleteDialog}
                path="/payment/:id/delete" />

            <Route path="/payment/:id/delete">
                {({ history, match }) => (
                    <PaymentDeleteDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => history.push('/payment')}
                    />
                )}
            </Route> 

            <PaymentCard />
        </PaymentUIProvider>
    );
}
