import axios from 'axios';
import { DonationURL } from '../../../../config/UrlEndPoint';

export const DONATION_URL = `${DonationURL}reconcile`;

// get data for Reconcile Payment
export function getReconcilePayment(user_uuid) {
	return axios.get(`${DONATION_URL}/v2/reconcile-payment/${user_uuid}`);
}

export function getReconcileV2(queryParams) {
	return axios.post(`${DONATION_URL}/v2/find`, queryParams);
}

export function getReconcileByDonaIdV2(donationId) {
	return axios.get(`${DONATION_URL}/v2/donation/${donationId}`);
}

export function approveReconcile(queryParams) {
	return axios.put(`${DONATION_URL}/v2/approve`, queryParams);
}

// items => filtered/sorted result
export function findReconcile(queryParams) {
	return axios.post(`${DONATION_URL}/find`, queryParams);
}

export function findReconcileQuery(queryParams) {
	return axios.post(`${DONATION_URL}/find/query`, queryParams);
}

//Get By ID
export function getReconcileId(uuid) {
	return axios.get(`${DONATION_URL}/${uuid}`);
}

//Create Donation Counter
export function createReconcile(data) {
	return axios.post(`${DONATION_URL}`, data);
}

export function createReconcileV2(data) {
	return axios.post(`${DONATION_URL}/v2`, data);
}

//Create Donation Counter
export function createReconcileDraft(data) {
	return axios.post(`${DONATION_URL}/draft`, data);
}

// GET LIST REFERENCE
export function getDonationReference(data) {
	return axios.post(`${DONATION_URL}/reference`, data)
}

// PUT UPDATE CONFIRMATION
export function updateReconcile(data) {
	return axios.put(`${DONATION_URL}/process`, data);
}

// DELETE Reconcile 

export function deleteReconcile(uuid) {
	return axios.delete(`${DONATION_URL}/delete/${uuid}`);

}

export function editReconcile(payload) {
	return axios.put(`${DONATION_URL}/edit`, payload);
}

export function editDraftReconcile(payload) {
	return axios.put(`${DONATION_URL}/edit_draft`, payload);
}



export function manualReconcile(uuid) {
	return axios.get(`${DONATION_URL}/update/${uuid}`);

}

