import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { useBankUIContext } from './BankUIContext';
import BankTable from './bank-table/BankTable';
import { BankFilter } from './bank-table/bank-filter/BankFilter'
import { RoleChecker } from '../../../../RoleChecker';

export const BankCard = () => {

	const bankUIContext = useBankUIContext();
	const bankUIProps = useMemo(() => {
		return {
			newBankButtonClick: bankUIContext.newBankButtonClick,
		};
	}, [bankUIContext]);

	return (
		<Card>
			<CardHeader title="Bank List">
				<CardHeaderToolbar>
					{RoleChecker('Create', 'bank') && <button
						type="button"
						className="btn btn-primary"
						onClick={bankUIProps.newBankButtonClick}
					>Bank Baru
					</button>}
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<BankFilter />
				<BankTable />
			</CardBody>
		</Card>
	);
};
