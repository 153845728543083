import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useBankUIContext } from '../BankUIContext';
import { BankEditDialogHeader } from './BankEditDialogHeader';
import { BankEditForm } from './BankEditForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/bank/bankAction';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../../_metronic/_helpers';

export const BankEditDialog = ({ id, show, onHide }) => {

    // Bank UI Context
    const bankUIContext = useBankUIContext();
    const bankUIProps = useMemo(() => {
        return {
            initBank: bankUIContext.initBank,
            queryParams: bankUIContext.queryParams,
            setQueryParams: bankUIContext.setQueryParams,
        };
    }, [bankUIContext]);

    // Bank Redux state
    const dispatch = useDispatch();
    const { actionsLoading, bankForEdit, error } = useSelector(
        (state) => ({
            actionsLoading: state.banks.actionsLoading,
            bankForEdit: state.banks.bankForEdit,
            error: state.banks.error
        }),
        shallowEqual,
    );

    if (error) {
        toast.error(error, toastOption)
    }
    useEffect(() => {
        // server call for getting Bank by id
        dispatch(actions.fetchBank(id));
    }, [id, dispatch]);

    const saveBank = (bank) => {
        if (!id) {
            dispatch(actions.createBank(bank)).then((response) => {
                toast.success(response.message, toastOption);
                dispatch(actions.fetchBanks(bankUIProps.queryParams)).then(() => onHide());
            });

        } else {
            dispatch(actions.updateBank(bank)).then((response) => {
                toast.success(response.message, toastOption);
                dispatch(actions.fetchBanks(bankUIProps.queryParams)).then(() => onHide());
            });
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="modal-sizes-title-lg"
        >
            <BankEditDialogHeader id={id} />
            <BankEditForm
                saveBank={saveBank}
                actionsLoading={actionsLoading}
                bank={bankForEdit || bankUIProps.initBank}
                onHide={onHide}
            />
        </Modal>
    );
};
