import React from 'react';
import { Route } from 'react-router-dom';
import { BankCard } from './BankCard';
import { BankEditDialog } from './bank-edit-dialog/BankEditDialog';
import { BankUIProvider } from './BankUIContext';
import { PrivateRoute } from "../../../../PrivateRoute";
import { RoleChecker } from '../../../../RoleChecker';


export function BankPage({ history }) {

    const bankUIEvents = {
        newBankButtonClick: () => {
            history.push('/mastd-bank/dona/new');
        },
        openEditBankPage: (id) => {
            history.push(`/mastd-bank/dona/${id}/edit`);
        },
    };

    return (
        <BankUIProvider bankUIEvents={bankUIEvents}>

            <PrivateRoute role={RoleChecker('Create', 'bank')} component={BankEditDialog}
                path="/mastd-bank/dona/new" />
            <PrivateRoute role={RoleChecker('Edit', 'bank')} component={BankEditDialog}
                path="/mastd-bank/dona/:id/edit" />

            <Route path="/mastd-bank/dona/new">
                {({ history, match }) => (
                    <BankEditDialog
                        show={match != null}
                        onHide={() => {
                            history.push('/mastd-bank/dona');
                        }}
                    />
                )}
            </Route>
            <Route path="/mastd-bank/dona/:id/edit">
                {({ history, match }) => (
                    <BankEditDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push('/mastd-bank/dona');
                        }}
                    />
                )}
            </Route>

            <BankCard />
        </BankUIProvider>
    );
}
