import * as requestFromServer from './bankMutationCrud'
import { callTypes, bankMutationSlice } from './bankMutationSlice'
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = bankMutationSlice;

export const fetchBankMutation = (payload) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer.getFindBankMutation(payload)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.bankMutationFetched({ total_items, items }));
        })
        .catch(error => {
            if (error.response.status === 403 && error.response.statusText === "Forbidden") {
                toast.warn(error.response.data.message, toastOption);
            }
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
};

export const fetchBankMutationById = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.paymentFetchedById({ bankForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getFindBankMutationById(id)
        .then(response => {
            const { data } = response.data;
            dispatch(actions.bankMutationFetchedById({ dataForEdit: data }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            } 
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createBankMutation = (body) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.insertBankMutation(body)
        .then(response => {
            dispatch(actions.bankMutationReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateBankMutation = (body, uuid) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updateBankMutation(body, uuid)
        .then(response => {
            dispatch(actions.bankMutationReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const deleteBankMutation = (uuid) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.deleteBankMutation(uuid)
        .then(response => {
            dispatch(actions.bankMutationReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};