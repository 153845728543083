import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}payment`;

export function getFindPayment(payload) {
    return axios.post(`${URL}`, payload);
}

export function getFindPaymentById(uuid) {
    return axios.get(`${URL}/${uuid}`);
}

export function insertPayment(body) {
    return axios.post(`${URL}/payment`, body);
}

export function updatePayment(body, uuid) {
    return axios.put(`${URL}/${uuid}`, body);
}

export function deletePayment(uuid) {
    return axios.delete(`${URL}/${uuid}`);
}




