import React from 'react';
import {currencyFormatter} from '../../../../../_metronic/_helpers/UtilityHelpers';

export function PaymentColumnFormatter(cellContent, row) {

    if (row.payment_edc_provider !== null && parseInt(row.payment_cash) !== 0) {
        return (
            <span>
                <strong style={{color: 'red'}}>Bank + Cash</strong>
            </span>
        );
    }

    if (row.payment_edc_provider !== null && parseInt(row.payment_cash) === 0) {
        return (
            <span>
                <strong style={{color: 'blue'}}>Bank</strong>
            </span>
        );
    }
    if (row.payment_edc_provider === null && parseInt(row.payment_cash) === 0) {
        return (
            <span>
                <strong style={{color: 'purple'}}>Partner</strong>
            </span>
        );
    } 

    return (
        <span>
            <strong style={{color: 'green'}}>Cash</strong>
        </span>
    );
}

export function PriceTotal(cellContent, row) {
    let amount=0
    for (let donationItemElement of row.donation_item) {
        amount+= Number(donationItemElement.amount)
    }
    return (
        <span>
            {currencyFormatter(parseInt(amount))}
        </span>
    );
}

export function PriceTotalCount(cellContent, row) {
    let sum = row.payment_cash + row.payment_edc
    const result = currencyFormatter(sum)
    return (
        <span>
            {result}
        </span>
    );
}

export function PriceAmount(cellContent, row) {
    return (
        <span>
            {currencyFormatter(parseInt(row.amount))}
        </span>
    );
}

export function TrxItemFormatter(cell, row) {
    return (
        <span>{cell.length}</span>
    );
}

export function NameFormatter(cellContent, row) {
    let name = [];
    for (let i = 1; i <= 7; i++) {
        let x = `name_${i}`;
        if (row[x]) {
            name.push(row[x]);
        }
    }
    return (
        <span>
			{name.join(', ')}
        </span>
    );
}

