import React, {useState} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

export function DonaturTransactionWidget({className}) {
    const tabs = {
        tab1: "kt_tab_pane_3_1",
        tab2: "kt_tab_pane_3_2",
        tab3: "kt_tab_pane_3_3",
    };
    const [activeTab, setActiveTab] = useState(tabs.tab1);

    return (
        <>
            {/* begin::Base Table Widget 6 */}
            <div className={`card card-custom ${className}`}>
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Donasi
            </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Lebih 200+ donasi
            </span>
                    </h3>
                    <div className="card-toolbar">
                        <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                            <li className="nav-item">
                                <a
                                    className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 &&
                                    "active"}`}
                                    data-toggle="tab"
                                    href={`#${tabs.tab1}`}
                                    onClick={() => setActiveTab(tabs.tab1)}
                                >
                                    Bulan
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 &&
                                    "active"}`}
                                    data-toggle="tab"
                                    href={`#${tabs.tab2}`}
                                    onClick={() => setActiveTab(tabs.tab2)}
                                >
                                    Minggu
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 &&
                                    "active"}`}
                                    data-toggle="tab"
                                    href={`#${tabs.tab3}`}
                                    onClick={() => setActiveTab(tabs.tab3)}
                                >
                                    Hari
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className="card-body pt-2 pb-0">
                    {/* begin::Table */}
                    <div className="table-responsive">
                        <table className="table table-borderless table-vertical-center">
                            <thead>
                            <tr>
                                <th className="p-0" style={{width: "50px"}}/>
                                <th className="p-0" style={{minWidth: "150px"}}/>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span className="symbol-label">
                        <SVG
                            className="h-75 align-self-end"
                            src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                        ></SVG>
                      </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <a
                                        href="#"
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                    >
                                        OZIP
                                    </a>
                                    <span className="text-muted font-weight-bold d-block">
                      Bapak Martin
                    </span>
                                </td>
                                <td className="text-right">
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                      Donasi
                    </span>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Rp. 200.000.000
                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span className="symbol-label">
                        <SVG
                            className="h-75 align-self-end"
                            src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                        ></SVG>
                      </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <a
                                        href="#"
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                    >
                                        ZAKI MAULANA
                                    </a>
                                    <span className="text-muted font-weight-bold d-block">

                    </span>
                                </td>
                                <td className="text-right">
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                      Donasi
                    </span>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Rp. 260.000.000
                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span className="symbol-label">
                        <SVG
                            className="h-75 align-self-end"
                            src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                        ></SVG>
                      </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <a
                                        href="#"
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                    >
                                        OZIP
                                    </a>
                                    <span className="text-muted font-weight-bold d-block">
                      Bapak Martin
                    </span>
                                </td>
                                <td className="text-right">
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                      Donasi
                    </span>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Rp. 200.000.000
                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* end::Table */}
                </div>
                {/* end::Body */}
            </div>
            {/* end::Base Table Widget 6 */}
        </>
    );
}