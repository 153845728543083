import React, { useEffect, useMemo } from "react";
import { useBankMutationUIContext } from "../BankMutationUIContext"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage, toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as uiHelpers from '../BankMutationUIHelpers';
import { toast } from "react-toastify";
import * as actions from '../../../_redux/bank-mutation/bankMutationAction';
import { currencyFormatter } from "../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import moment from "moment";

export function BankMutationTable() {
    const uiContext = useBankMutationUIContext();
    const uiProps = useMemo(() => {
        return {
            detailBankMutationButtonClick: uiContext.uiEvent.detailBankMutationButtonClick,
            queryParams: uiContext.queryParams,
            setQueryParams: uiContext.setQueryParams,
        }
    }, [uiContext]);

    // Getting curret state of log Login list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.bankMutation }),
        shallowEqual,
    );

    const { totalCount, entities, listLoading, error } = currentState;

    if (error) {
        toast.error(error, currentState)
    }

    const dispatch = useDispatch();

    useEffect(() => {
        //Requset ke Branch Action
        dispatch(actions.fetchBankMutation(uiProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiProps.queryParams, dispatch]);

    // Table columns
    const columns = [
        {
            dataField: 'batch_id',
            text: 'No Batch',
        },
        {
            dataField: 'channel_name',
            text: 'Nama Channel',
        },
        {
            dataField: 'user_name',
            text: 'Dibuat oleh',
        },
        {
            dataField: 'created_at',
            text: 'Tanggal dibuat',
            formatter: (data) => moment(data).format('DD MMM YYYY HH:ss')
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (_, row) => {
                return (
                    <OverlayTrigger
                        overlay={<Tooltip id="products-edit-tooltip">Detail Data</Tooltip>}
                    >
                        <a
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => uiProps.detailBankMutationButtonClick(row.uuid)}
                        > 
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                      />
                    </span>
                        </a>
                    </OverlayTrigger>
                )
            }
        },
    ];

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount ?? 0,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: uiProps.queryParams.pageSize ?? 10,
        page: uiProps.queryParams.pageNumber ?? 1,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities || []}
                                columns={columns}
                                onTableChange={getHandlerTableChange(
                                    uiProps.setQueryParams,
                                )}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};
