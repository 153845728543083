import React, { useMemo } from "react";
import { useBankMutationUIContext } from "./BankMutationUIContext"
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { BankMutationTable } from "./bank-mutation-table/BankMutationTable";

export const BankMutationCard = () => {
    const uiContext = useBankMutationUIContext();
    const uiProps = useMemo(() => {
        return {
			importBankMutationButtonClick: uiContext.uiEvent.importBankMutationButtonClick,
        };
    }, [uiContext]);

    return(
        <Card>
            <CardHeader title="Transaksi Bank Mutasi">
                <CardHeaderToolbar>					
					<button
						type="button"
						className="btn btn-primary mr-2"
						onClick={uiProps.importBankMutationButtonClick}
					> Import Mutasi Bank 
					</button>
				</CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <BankMutationTable />
            </CardBody>
        </Card>
    )
}