import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/payment/paymentAction';
import * as columnFormatters from './column-formatters';
import * as uiHelpers from '../PaymentUIHelpers';

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../../../_metronic/_helpers';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { usePaymentUIContext } from '../PaymentUIContext';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../../_metronic/_helpers';
import { RoleChecker } from '../../../../../RoleChecker';

export const PaymentTable = () => {
    // Log Login UI Context
    const paymentUIContext = usePaymentUIContext();
    const paymentUIProps = useMemo(() => {
        return {
            queryParams: paymentUIContext.queryParams,
            setQueryParams: paymentUIContext.setQueryParams,
            openEditPaymentPage: paymentUIContext.openEditPaymentPage,
            openDeletePaymentPage: paymentUIContext.openDeletePaymentPage,
        };
    }, [paymentUIContext]);

    // Getting curret state of log Login list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.payment }),
        shallowEqual,
    );

    const { totalCount, entities, listLoading, error } = currentState;

    if (error) {
        toast.error(error, toastOption)
    }

    const dispatch = useDispatch();

    useEffect(() => {
        //Requset ke Branch Action
        dispatch(actions.fetchPayment(paymentUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentUIProps.queryParams, dispatch]);

    const allowEdit = RoleChecker('Edit', 'bank');
    const allowDelete = RoleChecker('Edit', 'bank');
    
    // Table columns
    const columns = [
        {
            dataField: 'name',
            text: 'Metode Pembayaran',
        },
        {
            dataField: 'type',
            text: 'Type',
        },
        {
            dataField: 'merchant_id',
            text: 'MID Device',
            formatter: (cell) => cell || '-',
        },
        {
            dataField: 'bank_pooling_name',
            text: 'Bank Pengumpul',
            formatter: (_, row) => {
                return row.bank_pooling_account_number 
                    ? `${row.bank_pooling_account_number} | ${row.bank_pooling_account_type || ''}` 
                    : '';
            }
        },
        {
            dataField: 'channel_name',
            text: 'Nama Channel',
        },
        {
            dataField: 'branch_name',
            text: 'Nama Cabang',
        },
        {
            dataField: 'created_at',
            text: 'Dibuat tanggal',
            formatter: columnFormatters.DateColumnFormatter,
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditPaymentPage: paymentUIProps.openEditPaymentPage,
                openDeletePaymentPage: paymentUIProps.openDeletePaymentPage,
                allowEdit,
                allowDelete
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount ?? 0,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: paymentUIProps.queryParams.pageSize,
        page: paymentUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                onTableChange={getHandlerTableChange(
                                    paymentUIProps.setQueryParams,
                                )}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};
