import React, { useEffect, useState, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  toAbsoluteUrl,
} from "../../../../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { usePartnershipUIContext } from "../../PartnershipUIContext";
import { useHistory } from "react-router-dom";

export default function ThkReportTable({ thkPartnership = {} }) {
  const initQueryParams = {
    pageNumber: 1,
    pageSize: 10,
    filter: {},
  };

  const history = useHistory();

  const [queryParams, setQueryParams] = useState(initQueryParams);
  const [initValues, setInitValues] = useState(thkPartnership);
  const [showModal, setShowModal] = useState({
    campaignForm: false,
  });

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkPartnership }),
    shallowEqual
  );

  const { partnershipForEdit } = currentState;

  // Table columns
  const columns = [
    {
      dataField: "report_template_type",
      text: "Tipe",
    },
    {
      dataField: "updateAt",
      text: "Terakhir Diperbarui",
      formatter: (cell, row) => {
        return (
          <>
            {row.updatedAt
              ? new Intl.DateTimeFormat("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(new Date(row.updatedAt))
              : "-"}
          </>
        );
      },
    },
    {
      dataField: "name",
      text: "Diperbarui Oleh",
    },
    {
      dataField: "report_file",
      text: "Template Laporan",
      formatter: (cell, row) => {
        return (
          <a
            className="mx-3"
            style={{
              textDecoration: "underline",
              textDecorationColor: "blue",
            }}
            onClick={() => {
              history.push({
                pathname: `/qurban/partnership/${row.uuid}/manage/edit/report_template`,
                state: {
                  partnership_uuid: row.uuid,
                  period_year: row.thk_period.name,
                  period_uuid: row.thk_period.uuid,
                  report_file: row.report_file,
                  certificate_file: row.certificate_file,
                  file_type: "report_file",
                  report_template_type: row.report_template_type,
                },
              });
            }}
          >
            Template Laporan
          </a>
        );
      },
    },
    // {
    //   dataField: "certificate_file",
    //   text: "Template Sertifikat",
    //   formatter: (cell) => {
    //     if (cell) {
    //       const url = `${cell.adapter}${cell.path}`;
    //       return (
    //         <a
    //           className="mx-3"
    //           style={{
    //             textDecoration: "underline",
    //             textDecorationColor: "blue",
    //           }}
    //           onClick={() => window.open(url)}
    //         >
    //           {cell.filename}
    //         </a>
    //       );
    //     }
    //   },
    // },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        remote
        keyField="uuid"
        data={partnershipForEdit ? [].concat(partnershipForEdit) : []}
        columns={columns}
        onTableChange={getHandlerTableChange(setQueryParams)}
      >
        <PleaseWaitMessage
          entities={partnershipForEdit ? [].concat(partnershipForEdit) : []}
        />
        <NoRecordsFoundMessage
          entities={partnershipForEdit ? [].concat(partnershipForEdit) : []}
        />
      </BootstrapTable>
    </>
  );
}
