import React from 'react';


export function TypeChannelColumnFormatter(cellContent, row) {
    return (
        <>
            {`${row.channel.type} - ${row.channel.name}`}
        </>
    );
}
