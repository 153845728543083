import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './BankUIHelpers';

const BankUIContext = createContext();

export function useBankUIContext() {
    return useContext(BankUIContext);
}

export const BankUIConsumer = BankUIContext.Consumer;

export function BankUIProvider({ bankUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initBank = {
        name: '',
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initBank,
        newBankButtonClick: bankUIEvents.newBankButtonClick,
        openEditBankPage: bankUIEvents.openEditBankPage,
    };

    return (
        <BankUIContext.Provider value={value}>
            {children}
        </BankUIContext.Provider>
    );

}
