import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { findBankPooling } from "../../../MasterData/_redux/bank-pooling/bankPoolingCrud";
import { useLoadOptions } from "../../../../hooks/use-load-options";
import { createReconcileV2, getReconcilePayment } from "../../_redux/reconcile/reconcileCrud";
import { getDonationPayment } from "../../_redux/donation/donationCrud";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import moment from "moment";

const Context = createContext();

export function useReconcilePaymentUIContext() {
    return useContext(Context);
}

export function ReconcilePaymentUIProvider({ uiEvent, children }) {
    const { currentState } = useSelector(
		(state) => ({ currentState: state.auth.user }),
		shallowEqual,
	);

	const initInputData = {
		bank_pooling_uuid: '',
		payment_name: '',
		payment_type: '',
		donation_payment_uuids: [],
		diff_description: '',
		channel_charge: 0,
		diff_amount: 0,
		amount: '',
		trx_date: '',
		file: null,
	};

	const [selectDate, setSelectDate] = useState({ index: null, date: '' });
    const [selectCard, setSelectCard] = useState({ index: null, data: {} });
	const [cardPayments, setCardPayments] = useState([]);
	const [showDetailPay, setShowDetailPay] = useState({ open: false, detailData: {} });
	const [inputData, setInputData] = useState(initInputData);
	const [reconcilePayments, setReconcilePayments] = useState([]);
	const [donationPayments, setDonationPayments] = useState([]);

	const updateInputData = (field, value) => {
        setInputData((prev) => ({
          ...prev,
          [field]: value,
        }));
    };

	// request to api
	const branch_uuid = currentState.role === 'SuperAdmin' ? currentState.channel.branch_uuid : '';
	const [defaultBankPoolingOptions, loadBankPoolingOptions] = useLoadOptions(
        (input) => findBankPooling({ filter: { account_number: input, branch_uuid: branch_uuid }, pageNumber: 1, pageSize: 15 }),
        "items",
        ({ uuid: value, account_number, account_type }) => ({ label: account_number + (account_type ? ` | ${account_type}` : ''), value })
    );

	const fetchReconcilePayment = useCallback(async () => {
        try {
			const userId = currentState.uuid;
            const result = await getReconcilePayment(userId);
            setReconcilePayments(result.data.data);
        } catch (error) {
            console.error('Failed to fetch reconcile payment data:', error);
        }
    }, []);

	const fetchDonationPayment = useCallback(async (query) => {
        try {
			const queryParams = {
				filter: {
					user_uuid: query.user_uuid || currentState.uuid,
					status_dona: 'PAID',
					date: query.date || selectDate.date,
					payment_uuid: query.payment_uuid || selectCard.data.payment_uuid,
				},
				pageNumber: 1,
				pageSize: 100,
			}
            const result = await getDonationPayment(queryParams);
			setDonationPayments(result.data.data.items);
        } catch (error) {
            console.error('Failed to fetch donation payment data:', error);
        }
    }, []);

	// create reconcile payment
	const createReconcilePayment = async () => {
		const value = inputData;

		try {
			// validasi file
			const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
			const file = value.file;
	
			if (!allowedFileTypes.includes(file.type)) {
				toast.error('Only JPG, PNG, or JPEG files are allowed', toastOption);
				return;
			}

			// init formData
			const formData = new FormData();
			formData.append('payment_name', value.payment_name);
			formData.append('payment_type', value.payment_type);
			formData.append('donation_payment_uuids', JSON.stringify(value.donation_payment_uuids));
        	formData.append('bank_pooling_uuid', value.bank_pooling_uuid);
        	formData.append('diff_description', value.diff_description || '-');
        	formData.append('channel_charge', value.channel_charge);
        	formData.append('diff_amount', value.diff_amount);
        	formData.append('amount', value.amount);
        	formData.append('trx_date', moment(Date.now()).format('YYYY-MM-DD'));
        	formData.append('file', value.file);

			const res = await createReconcileV2(formData);
			toast.success(res.data.message, toastOption);

			// refresh page
			// Tunggu 1.5 detik (1500 milidetik) sebelum refresh
			setTimeout(() => {
			    window.location.reload();
			}, 1500);

		} catch (error) {
			toast.error(error, toastOption)
		}
	}

	// data user
	const userData = {
		uuid: currentState.uuid,
		name: currentState.name,
		role: currentState.role,
		channel: {...currentState.channel, uuid: currentState.channel_uuid},
	} || {};

	useEffect(() => {
		fetchReconcilePayment();
	}, []);

	useEffect(() => {
		// select first loading
		if (reconcilePayments.length >= 1 && !selectDate.index && !selectCard.index) {
			setSelectDate({ index: 0, date: reconcilePayments[0].created_at });
			setCardPayments(reconcilePayments[0].data);
			setSelectCard({ index: 0, data: reconcilePayments[0].data[0]});
		}
	}, [reconcilePayments]);

	useEffect(() => {
		if (showDetailPay.open && showDetailPay.detailData) {
			fetchDonationPayment({ 
				user_uuid: userData.uuid, 
				date: selectDate.date ,
				payment_uuid: showDetailPay.detailData.uuid,
			});
		}
	}, [showDetailPay]);

	useEffect(() => {
		// diff amount
		if (inputData.channel_charge >= 1) {
			const diff_amount = +selectCard.data.total_amount - +inputData.channel_charge;
			updateInputData('diff_amount', diff_amount.toString());
		}

		if (selectCard.data.donation_payment_uuids && selectCard.data.donation_payment_uuids.length >= 0) {
			updateInputData('donation_payment_uuids',selectCard.data.donation_payment_uuids);
			updateInputData('payment_name', selectCard.data.payment_name);
			updateInputData('payment_type', selectCard.data.payment_type);
			updateInputData('amount', selectCard.data.total_amount);
		}
	}, [inputData.channel_charge, selectCard.data]);

    const value = {
		userData,
		reconcilePayments,
		cardPayments,
		setCardPayments,
		defaultBankPoolingOptions,
		loadBankPoolingOptions,
		showDetailPay,
		setShowDetailPay,
		selectCard,
		setSelectCard,
		selectDate,
		setSelectDate,
		donationPayments,
		setDonationPayments,
		fetchDonationPayment,
		createReconcilePayment,
		inputData,
		updateInputData,
	};

    return (
		<Context.Provider value={value}>
			{children}
		</Context.Provider>
	);
}